//import Web3 from 'web3';
//import { ethers } from "ethers";

import React from 'react';
import { QrReader } from "react-qr-reader";
import Checkout from './Check';

import { useState, useEffect } from "react";
import './App.css';


//import logo from './WEBTHREE2.png';
import logo from './pw3bis.png';


//import {
  // Button,
  // Input,
//} from 'reactstrap';

  
  function Start() {


    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const thelang = urlParams.get("lang");

    
  const [data, setData] = useState('No result');
  const [resultqr, setResultqr] = useState(0);
  const [qrstart, setqrstart] = useState(0);
  const [lang, setlang] = useState(1);

  
  useEffect(() => {
      
    if (thelang == 1) {
      setlang(1);
    }

    if (thelang == 2) {
      setlang(2);
    }
    

    //async function fetchdata() {
      
   
   //}
   //fetchdata();

     
  
   
},[]);

    
function StartReader() {

if (qrstart == 0) {
  setqrstart(1);
}

if (qrstart == 1) {
  setqrstart(0);
}



}  


function SelectLanguage() {
  
  if (lang == 1) {
    setlang(2);
  } 

  if (lang == 2) {
    setlang(1);
  } 


}











//{resultqr === 1 &&
  //<Checkout thedata={data} thelang={lang} />}
  

    
    return (


    <div className="App">
    <header className="App-header">
  
    

{resultqr == 0 &&

<div class="card">


<div class="row">

<div class="col">

{(resultqr == 0 && lang == 1) &&
  <h6 class="text text-start text-dark"><a href={'https://web3debit.shop?lang=' + lang}><img src={logo} class="img-fluid" width="40" height="auto"></img></a> <b>Leer QR</b></h6>}


{(resultqr == 0 && lang == 2) &&
  <h6 class="text text-start text-dark"><a href={'https://web3debit.shop?lang=' + lang}><img src={logo} class="img-fluid" width="40" height="auto"></img></a> <b>Read QR</b></h6>}








</div>

<div class="col">
{lang == 1 &&
  <h1 class="text text-end text-muted" onClick={SelectLanguage}><div class="pointer">English</div></h1>}

{lang == 2 &&
  <h1 class="text text-end text-muted" onClick={SelectLanguage}><div class="pointer">Español</div></h1>}



</div>



</div>  



<p></p>



{(qrstart == 0 && resultqr == 0 && lang == 1) &&
<button type="button" class="btn btn-primary" onClick={StartReader}>Leer</button>}



{(qrstart == 0 && resultqr == 0 && lang == 2) &&
  <button type="button" class="btn btn-primary" onClick={StartReader}>Read</button>}



{(qrstart == 1 && resultqr == 0 && lang == 1) &&
  <button type="button" class="btn btn-primary" onClick={StartReader}>Cancelar</button>}




{(qrstart == 1 && resultqr == 0 && lang == 2) &&

<button type="button" class="btn btn-primary" onClick={StartReader}>Cancel</button>}



<p></p>





{(resultqr === 0 && qrstart == 1) &&      
      <QrReader
        onResult={(result, error) => {
          if (!!result) {
            setData(result?.text);
            setResultqr(1);
                        
          }

          if (!!error) {
            console.info(error);
          }
        }}
        style={{ width: '100%' }}
        constraints={ {facingMode: 'environment'} }
      />}





</div>}



      
{resultqr === 1 &&
  <Checkout thedata={data} thelang={lang} />}





</header>


  </div>
  
  );
  }
  
  export default Start;