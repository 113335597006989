import React from 'react';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import ReactDOM from 'react-dom/client';
import './index.css';
import QR from './Start';
import Link from './CheckLink';
//import App from './App';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';



export default function App() {
  return (
<BrowserRouter>
      
      
      <Routes>
        <Route path="/">
        <Route index element={<QR />} />
        <Route path="link" element={<Link />} /></Route>
        
      </Routes>    
      
      
</BrowserRouter>

);
}










const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


