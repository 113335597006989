import Web3 from 'web3';
import React from 'react';

import { ethers } from "ethers";
//import AbiGateway from "./contracts/Gateway.json";
//import AbiMyToken from "./contracts/Mytoken.json";

import Checkout1 from './Finish';




import { useState, useEffect } from "react";
import './App.css';


import tether from './usdt.svg';
import usdc from './usdc.svg';
//import logo from './WEBTHREE2.png';
import logo from './pw3bis.png';

import busd from './busd.png';

import maticlogo from './matic.svg';
import binancelogo from './binance.svg';
import avalanchelogo from './avalanchelogo.png';
//import fantomlogo from './fantom.svg';


//import {
  //Button,
  //Input,
//} from 'reactstrap';




function CheckLink() {

  
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const lang = urlParams.get("lang");
  const dataqr = urlParams.get("data");
  
  
  

  


  const [store, setstore] = useState('');
  const [token, settoken] = useState('');
  const [chainid, setchainid] = useState(0);
  const [qrok, setqrok] = useState(0);

  const [nametoken, setnametoken] = useState('');
  const [amount, setamount] = useState();

  const [thelang, setthelang] = useState(1);

  //const [dataqr, setDataqr] = useState(arr.thedata);

  const [mensaje, setmensaje] = useState("");
  const [decimalstoken, setdecimalstoken] = useState(0);
  const [amountpay, setamountpay] = useState(0);
  const [memo, setmemo] = useState();



    useEffect(() => {
    
      if (lang == 1) {
        setthelang(1);
      }
  
      if (lang == 2) {
        setthelang(2);
      }
  
      




     
     //}
     CheckScan();

       
    
     
  },[]);


  async function CheckScan() {


      if (dataqr == undefined) {
        setqrok(1);
      } else {


      var marketusdt = '0xc2132D05D31c914a87C6611C10748AEb04B58e8F';
      var marketusdc = '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174';
          
      var marketbusd = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56';
      var marketusdtbnb = '0x55d398326f99059fF775485246999027B3197955';


      var marketusdtava = '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7';
      var marketusdcava = '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E';




      var toBN = Web3.utils.toBN;

      var thearray = [];
      thearray = dataqr.split(',');
      
    
      if (thearray[0] == undefined || thearray[1] == undefined || thearray[2] == undefined || thearray[3] == undefined || thearray[4] == undefined || thearray[5] == undefined) {
        setqrok(1);
      
      } else {
    
        
        var thestore = thearray[0];
        var thetoken = thearray[2];
        var thechain = thearray[1];
        var thetype = thearray[3];
        var thevalue = thearray[4];
        var theref = thearray[5];

        if (Web3.utils.isAddress(thestore) && Web3.utils.isAddress(thetoken) && !isNaN(thechain) && !isNaN(thetype) && !isNaN(thevalue) && !isNaN(theref)) {
    
          if (thetype != 1 && thetype != 2) {
            setqrok(1);    
          }
          
          setstore(thearray[0]);
          settoken(thearray[2]);
          setchainid(thearray[1]);

          
          
          if (thechain != 137 && thechain != 56 && thechain != 43114) {
            setqrok(1);    
          }


          if (thechain == 137 && thetype == 1) {
            if (thetoken.toLowerCase().localeCompare((marketusdt).toLowerCase()) == 0) {
              setnametoken("USDT");
              setqrok(2);
              setdecimalstoken(6);
            } else {
              if (thetoken.toLowerCase().localeCompare((marketusdc).toLowerCase()) == 0) {
               setnametoken("USDC");
                setqrok(2);
                setdecimalstoken(6);
              } else {
                  setqrok(1);
              } 
            }  
          }
       
          
          if (thechain == 43114 && thetype == 1) {
            if (thetoken.toLowerCase().localeCompare((marketusdtava).toLowerCase()) == 0) {
              setnametoken("USDT");
              setqrok(2);
              setdecimalstoken(6);
            } else {
              if (thetoken.toLowerCase().localeCompare((marketusdcava).toLowerCase()) == 0) {
               setnametoken("USDC");
                setqrok(2);
                setdecimalstoken(6);
              } else {
                  setqrok(1);
              } 
            }  
          }
       

          
        
            
          if (thechain == 56 && thetype == 1) {
            if (thetoken.toLowerCase().localeCompare((marketbusd).toLowerCase()) == 0) {
              setnametoken("BUSD");
              setqrok(2);
              setdecimalstoken(18);
      
      

            } else {
              if (thetoken.toLowerCase().localeCompare((marketusdtbnb).toLowerCase()) == 0) {
               setnametoken("USDT");
                setqrok(2);
                setdecimalstoken(18);
              } else {
                  setqrok(1);
              } 
           }
      }

        
      
      try{  
          if (thechain == 137 && thetype == 2) {
            if (thetoken.toLowerCase().localeCompare((marketusdt).toLowerCase()) == 0) {
              setnametoken("USDT");
              setdecimalstoken(6);
                if (thevalue > 0 && theref > 0) {
                  setamountpay(String(toBN(thevalue)));
                  setamount(String(ethers.utils.formatUnits(thevalue, 6)));
                  setmemo(String(theref));
                  setqrok(3);
                } else {
                  setqrok(1);
                }

            
            } else {
              if (thetoken.toLowerCase().localeCompare((marketusdc).toLowerCase()) == 0) {
               setnametoken("USDC");
               setdecimalstoken(6);
                if (thevalue > 0 && theref > 0) {
                  setamountpay(String(toBN(thevalue)));
                  setamount(String(ethers.utils.formatUnits(thevalue, 6)));
                  setmemo(String(theref));
                  setqrok(3);
                } else {
                  setqrok(1);
                }
              
              } else {
                  setqrok(1);
              } 
            }  
          }
        }catch(e){
          setqrok(1);
        }




        try{  
          if (thechain == 43114 && thetype == 2) {
            if (thetoken.toLowerCase().localeCompare((marketusdtava).toLowerCase()) == 0) {
              setnametoken("USDT");
              setdecimalstoken(6);
                if (thevalue > 0 && theref > 0) {
                  setamountpay(String(toBN(thevalue)));
                  setamount(String(ethers.utils.formatUnits(thevalue, 6)));
                  setmemo(String(theref));
                  setqrok(3);
                } else {
                  setqrok(1);
                }

            
            } else {
              if (thetoken.toLowerCase().localeCompare((marketusdcava).toLowerCase()) == 0) {
               setnametoken("USDC");
               setdecimalstoken(6);
                if (thevalue > 0 && theref > 0) {
                  setamountpay(String(toBN(thevalue)));
                  setamount(String(ethers.utils.formatUnits(thevalue, 6)));
                  setmemo(String(theref));
                  setqrok(3);
                } else {
                  setqrok(1);
                }
              
              } else {
                  setqrok(1);
              } 
            }  
          }
        }catch(e){
          setqrok(1);
        }











       try{  
          if (thechain == 56 && thetype == 2) {
            if (thetoken.toLowerCase().localeCompare((marketbusd).toLowerCase()) == 0) {
              setnametoken("BUSD");
              setdecimalstoken(18);
                if (thevalue > 0 && theref > 0) {
                  setamountpay(String(toBN(thevalue)));
                  setamount(String(ethers.utils.formatUnits(thevalue, 18)));
                  setmemo(String(theref));
                  setqrok(3);
                } else {
                  setqrok(1);
                }


   
            
            } else {
              if (thetoken.toLowerCase().localeCompare((marketusdtbnb).toLowerCase()) == 0) {
               setnametoken("USDT");
               setdecimalstoken(18);
                  if (thevalue > 0 && theref > 0) {
                  setamountpay(String(toBN(thevalue)));
                  setamount(String(ethers.utils.formatUnits(thevalue, 18)));
                  setmemo(String(theref));
                  setqrok(3);
                } else {
                  setqrok(1);
                }
            
            
              } else {
                  setqrok(1);
              } 
           }
      }
      
        }catch(e){
          setqrok(1);
        }

          
          
          } else {
            setqrok(1);
          }
        
      }
      
      }
    }




  function ReloadScan() {
    window.location.reload();
  }



function Pagar() {

setmensaje("");
var errorpay = 0;

var theamount = amount;
var theamountwidget = amount;
var thememo = memo;

if (theamount == 0 || theamount === undefined || isNaN(theamount) || thememo == 0 || thememo === undefined || isNaN(thememo)) {

  if (thelang == 1) {
  setmensaje("Ingrese el importe a pagar y referencia");
  }  

  if (thelang == 2) {
  setmensaje("Fill the amount to pay and reference"); 
  }  
  

} else {


thememo = String(Math.trunc(thememo));  
setmemo(Number(thememo));

try{
theamount = ethers.utils.parseUnits(theamount, decimalstoken);
}catch(e){

  errorpay = 1;  

  if (thelang == 1) {
    setmensaje("Maximo admitido " + decimalstoken + " decimales");
    }  
  
    if (thelang == 2) {
    setmensaje("Maximum " + decimalstoken + " decimals"); 
    }

};

if (errorpay == 0) {


//theamount = String(Math.trunc(theamount * (10 ** decimalstoken)));
setamountpay(String(theamount));


theamountwidget = ethers.utils.formatUnits(theamount, decimalstoken);




//theamountwidget = String(theamount * (10 ** decimalstoken));
//theamountwidget = String(theamount / (10 ** decimalstoken));

setamount(String(theamountwidget));


if (Number(theamount) === 0) {

  if (thelang == 1) {
    setmensaje("El importe debe ser mayor a cero");
    }  
  
    if (thelang == 2) {
    setmensaje("Amount must be greater than 0"); 
    }  
  
} else {

  setqrok(3);

}


}


}

}




function SelectLanguage() {

  
  setmensaje("");


  if (thelang == 1) {
    setthelang(2);
  } 

  if (thelang == 2) {
    setthelang(1);
  } 


}






//{qrok == 3 &&

  //<Checkout1 thetoken={token} theamountwidget={amount} theamountpay={amountpay} thestore={store} thechainid={chainid} thenametoken={nametoken} thelang={arr.thelang} thememo={memo} />
  
  




  return (

    
<div className="App">
<header className="App-header">


{qrok == 2 &&
<div class="card">











<div class="row">
<div class="col">  


{(qrok == 2 && thelang == 1) &&
  <h6 class="text text-start text-dark"><a href={'https://web3debit.shop?lang=' + thelang}><img src={logo} class="img-fluid" width="40" height="auto"></img></a> <b>Detalle</b></h6>}

{(qrok == 2 && thelang == 2) &&
  <h6 class="text text-start text-dark"><a href={'https://web3debit.shop?lang=' + thelang}><img src={logo} class="img-fluid" width="40" height="auto"></img></a> <b>Details</b></h6>}




</div>

<div class="col">

{thelang == 1 &&
  <h1 class="text text-end text-muted" onClick={SelectLanguage}><div class="pointer">English</div></h1>}

{thelang == 2 &&
  <h1 class="text text-end text-muted" onClick={SelectLanguage}><div class="pointer">Español</div></h1>}



</div>
</div>


<p></p>





<div class="table-responsive-sm table-borderless bg-transparent">
<table class="table">
<tbody>
  
<tr>
<td>

<h1 class="text text-start">Token</h1>
</td>

<td>
{nametoken == "USDT" &&  
<h1 class="text text-end"><img src={tether} class="img-fluid" width="20" height="auto"></img> USDT</h1>}

{nametoken == "USDC" &&  
<h1 class="text text-end"><img src={usdc} class="img-fluid" width="20" height="auto"></img> USDC</h1>}

{nametoken == "BUSD" &&  
<h1 class="text text-end"><img src={busd} class="img-fluid" width="20" height="auto"></img> BUSD</h1>}




</td>
</tr>

<tr>
<td>
{thelang == 1 &&
  <h1 class="text text-start">a Cadena</h1>}

{thelang == 2 &&
  <h1 class="text text-start">to Chain</h1>}
</td>

<td>

{chainid == 137 &&
  <h1 class="text text-end"><img src={maticlogo} class="img-fluid" width="20" height="auto"></img> Polygon</h1>}




{chainid == 43114 &&
  <h1 class="text text-end"><img src={avalanchelogo} class="img-fluid" width="20" height="auto"></img> Avalanche</h1>}



{chainid == 56 &&
  <h1 class="text text-end"><img src={binancelogo} class="img-fluid" width="20" height="auto"></img> BNB Smart Chain</h1>}




</td>
</tr>


<tr>
<td>
{thelang == 1 &&
  <h1 class="text text-start">Pagar a</h1>}

{thelang == 2 &&
  <h1 class="text text-start">Pay to</h1>}
</td>

<td>
  <h1 class="text text-end">{store.substr(0,6)}...{store.substr(-4)}</h1>
</td>
</tr>  




<tr>
<td>
{thelang == 1 &&
  <h1 class="text text-start">Importe</h1>}

{thelang == 2 &&
  <h1 class="text text-start">Amount</h1>}
</td>




<td>

{thelang == 1 &&
    <div class="text text-end"><input type="number" class="form-control" placeholder="monto a pagar" value={amount} onChange={e => setamount(e.target.value)} /></div>}

{thelang == 2 &&
    <div class="text text-end"><input type="number" class="form-control" placeholder="amount to pay" value={amount} onChange={e => setamount(e.target.value)} /></div>}


</td>
</tr>



<tr>
<td>
{thelang == 1 &&
  <h1 class="text text-start">Ref #</h1>}

{thelang == 2 &&
  <h1 class="text text-start">Ref #</h1>}
</td>


<td>


{thelang == 1 &&
    <div class="text text-end"><input type="number" class="form-control" placeholder="referencia transaccion #" value={memo} onChange={e => setmemo(e.target.value)} /></div>}

{thelang == 2 &&
    <div class="text text-end"><input type="number" class="form-control" placeholder="transaction reference #" value={memo} onChange={e => setmemo(e.target.value)} /></div>}


</td>
</tr>



</tbody>
</table>
</div>



{mensaje.length > 0 &&


<div class="alert alert-danger" role="alert">
<b>{mensaje}</b>
</div>}

    


<button type="button" class="btn btn-primary" onClick={Pagar}>CheckOut</button>




</div>}  






{qrok == 1 &&
<div class="card">


<div class="row">
<div class="col">  




  <h6 class="text text-start text-dark"><a href={'https://web3debit.shop?lang=' + thelang}><img src={logo} class="img-fluid" width="40" height="auto"></img></a> <b>Link</b></h6>



</div>


<div class="col">

{thelang == 1 &&
  <h1 class="text text-end text-muted" onClick={SelectLanguage}><div class="pointer">English</div></h1>}

{thelang == 2 &&
  <h1 class="text text-end text-muted" onClick={SelectLanguage}><div class="pointer">Español</div></h1>}



</div>
</div>


<p></p>



{thelang == 1 &&  

  <div class="alert alert-danger" role="alert">
  <b>El Link no es válido</b>
  </div>}


    {thelang == 2 &&  

      <div class="alert alert-danger" role="alert">
      <b>The Link is not valid</b>
      </div>}
    


</div>}




{qrok == 3 &&
<Checkout1 thetoken={token} theamountwidget={amount} theamountpay={amountpay} thestore={store} thechainid={chainid} thenametoken={nametoken} thelang={thelang} thememo={memo} thedecimalstoken={decimalstoken} />}





    
</header>
 </div>  

);

}


export default CheckLink;