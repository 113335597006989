import Web3 from 'web3';

import { ethers } from "ethers";

import qs from "qs";

//import AbiGateway from "./contracts/Gateway.json";
import AbiMyToken from "./contracts/Mytoken.json";
import AbiQuoter from "./contracts/Quoter.json";
//import AbiFactory from "./contracts/Factory.json";
import AbiRouter from "./contracts/RouterV3.json";
import AbiRouter0x from "./contracts/Router0x.json";
import AbiRouterV2 from "./contracts/RouterV2.json";
import AbiFee from "./contracts/FeeStargate.json";
import AbiStargate from "./contracts/RouterStargate.json";
import AbiPancake from "./contracts/PancakeRouter.json";



import Onboard from '@web3-onboard/core';
import injectedModule from '@web3-onboard/injected-wallets';
import coinbaseWalletModule from '@web3-onboard/coinbase';



import { useState, useEffect } from "react";
import './App.css';


import tether from './usdt.svg';
import usdc from './usdc.svg';
//import logo from './WEBTHREE2.png';
import logo from './pw3bis.png';


//import icono from './WEBTHREE1.png';
import icono from './pw3bis.png';


import pw3 from './PW3.png';
import update from './update.png';
import desconectar from './desconectar.png';
import yes from './yes.png';
import busd from './busd.png';
import ars from './nuars.png';
import sol from './numpen.png';

//import logowallet from './WEBTHREE1.png';
import logowallet from './pw3bis.png';


import maticlogo from './matic.svg';
import binancelogo from './binance.svg';
import avalanchelogo from './avalanchelogo.png';
import fantomlogo from './fantom.svg';
import info from './info.png';




//import {
//   Button,
//   Input,
//} from 'reactstrap';




function Finish(arr) {


const GAS_CROSS_CHAIN = 350000;  

const NATIVE137 = '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270';
const NATIVE56 = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c';
const NATIVE43114 = '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7';


const WEB3_ROUTER_ADDRESS137 = '0x59E0c044e55Bce7d26397536b396567762c36E4F';
const WEB3_ROUTER_ADDRESS56 = '0x846cE1068B61eCC408b84034A0d9d5D64933160C';
const WEB3_ROUTER_ADDRESS43114 = '0x150aE9FE2f79FfA41c1BA00aaCAED52F02A004a1';


//const STARGATE_FEE_CONTRACT137 = '0xA0732186f556F034CF9930B7796Bc3a03E614750';
//const STARGATE_FEE_CONTRACT56 = '0xCa46058a5682B13c44F4Dd2558aFDEbf3A28f41f';
//const STARGATE_FEE_CONTRACT43114 = '0x7f0369206D8a700514574dAAa0634B8A1F7149d7';


const STARGATE_FEE_CONTRACT137 = '0xb279b324Ea5648bE6402ABc727173A225383494C';
const STARGATE_FEE_CONTRACT56 = '0xCA6522116e8611A346D53Cc2005AC4192e3fc2BC';
const STARGATE_FEE_CONTRACT43114 = '0x5E8eC15ACB5Aa94D5f0589E54441b31c5e0B992d';





const STARGATE_ROUTER137 = '0x45A01E4e04F14f7A4a6702c74187c5F6222033cd';
const STARGATE_ROUTER56 = '0x4a364f8c717cAAD9A442737Eb7b8A55cc6cf18D8';
const STARGATE_ROUTER43114 = '0x45A01E4e04F14f7A4a6702c74187c5F6222033cd';


const STARGATE_CHAIN_ID137 = 109;
const STARGATE_CHAIN_ID56 = 102;
const STARGATE_CHAIN_ID43114 = 106;


const SLIPPAGE = 50;
const SLIPPAGE0X = 49;
const SLIPPAGESTARGATE = 50;
const MAXFEESTARGATE = 50;
const COUNTERDELAY = 45;



const bnbtokens = [  
  {value: '0x0000000000000000000000000000000000000000', text: 'BNB'},
  {value: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', text: 'BUSD'},
  {value: '0x55d398326f99059fF775485246999027B3197955', text: 'USDT'},
  {value: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d', text: 'USDC'},
  {value: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', text: 'DAI'},
  {value: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', text: 'WBNB'},
  {value: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8', text: 'ETH'},
  {value: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', text: 'BTCB'},
  {value: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47', text: 'ADA'},
  {value: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82', text: 'CAKE'},
  {value: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43', text: 'DOGE'},
  {value: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE', text: 'XRP'},

];



const polygontokens = [
  {value: '0x0000000000000000000000000000000000000000', text: 'MATIC'},
  {value: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F', text: 'USDT'},
  {value: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', text: 'USDC'},
  {value: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063', text: 'DAI'},
  {value: '0xE0B52e49357Fd4DAf2c15e02058DCE6BC0057db4', text: 'agEUR'},
  {value: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270', text: 'WMATIC'},
  {value: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619', text: 'WETH'},
  {value: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6', text: 'WBTC'},
  {value: '0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F', text: 'BNB'},
  {value: '0xD6DF932A45C0f255f85145f286eA0b292B21C90B', text: 'AAVE'},
  {value: '0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3', text: 'BAL'},
  {value: '0xB0B195aEFA3650A6908f15CdaC7D92F8a5791B0B', text: 'BOB'},
  {value: '0x172370d5Cd63279eFa6d502DAB29171933a610AF', text: 'CRV'},
  {value: '0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39', text: 'LINK'},
  {value: '0xa3Fa99A148fA48D14Ed51d610c367C61876997F1', text: 'miMATIC'},
  {value: '0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a', text: 'SUSHI'},
  {value: '0xb33EaAd8d922B1083446DC23f610c2567fB5180f', text: 'UNI'},
  {value: '0xDC3326e71D45186F113a2F448984CA0e8D201995', text: 'XSGD'},
  
];


const avalanchetokens = [
  {value: '0x0000000000000000000000000000000000000000', text: 'AVAX'},
  {value: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7', text: 'USDT'},
  {value: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E', text: 'USDC'},
  {value: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118', text: 'USDT.e'},
  {value: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664', text: 'USDC.e'},
  {value: '0xC891EB4cbdEFf6e073e859e987815Ed1505c2ACD', text: 'EUROC'},
  {value: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7', text: 'WAVAX'},
  {value: '0x50b7545627a5162f82a992c33b87adc75187b218', text: 'WBTC.e'},
  {value: '0x152b9d0fdc40c096757f570a51e494bd4b943e50', text: 'BTC.b'},
  {value: '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab', text: 'WETH.e'},
];

















const thetoken = arr.thetoken;
const theamountwidget =  String(arr.theamountwidget);
const theamountpay =  arr.theamountpay;
const thestore = arr.thestore;
const thechainid = Number(arr.thechainid);
const thenametoken = arr.thenametoken;
const thememo = arr.thememo;
const thedecimalstoken = arr.thedecimalstoken;


const [isconnected, setisconnected] = useState(0);
const [account, setaccount] = useState("");
const [provider1, setprovider1] = useState("");
const [mensaje, setmensaje] = useState("");
const [theprovider, settheprovider] = useState("");
const [chainid, setchainid] = useState("");
const [chainpay, setchainpay] = useState("");
const [tokenpay, settokenpay] = useState("");
const [balancetokenpay, setbalancetokenpay] = useState(0);
const [amountIn, setAmountIn] = useState(0);
const [route, setroute] = useState(0);
const [poolfee, setpoolfee] = useState(0);
const [providerprotocol, setproviderprotocol] = useState('');
const [nativeToken, setnativeToken] = useState('');
const [decimalspay, setdecimalspay] = useState(0);
const [amountslippage, setamountslippage] = useState(0);
const [trade, settrade] = useState(0);
const [amountInMax, setAmountInMax] = useState(0);
const [routeraddress, setrouteraddress] = useState('');
const [tokencontract, settokencontract] = useState('');
const [allowance, setallowance] = useState(0);
const [stargatefeecontract, setstargatefeecontract] = useState(0);
const [stargatechainid, setstargatechainid] = useState(0);
const [destpool, setdestpool] = useState(0);
const [srcpool, setsrcpool] = useState(0);
const [stargaterouter, setstargaterouter] = useState(0);
const [gasfee, setgasfee] = useState(0);
const [srcpoolbridge, setsrcpoolbridge] = useState(0);
const [tokenpaybridge, settokenpaybridge] = useState("");

const [srcpoolbridge1, setsrcpoolbridge1] = useState(0);
const [tokenpaybridge1, settokenpaybridge1] = useState("");


const [amountInMaxBridge, setAmountInMaxBridge] = useState(0);
const [amountInBridge, setAmountInBridge] = useState(0);
const [amountslippagebridge, setamountslippagebridge] = useState(0);

const [routerreceiver, setrouterreceiver] = useState('');
const [decimalsfixed, setdecimalsfixed] = useState(0);

const [amountpayfixed, setamountpayfixed] = useState(0);
const [decimalsbridge, setdecimalsbridge] = useState(0);

const [thelang, setthelang] = useState(arr.thelang);
//const [polygontokens, setpolygontokens] = useState();
//const [bnbtokens, setbnbtokens] = useState();

//const [avalanchetokens, setavalanchetokens] = useState();

const [path, setpath] = useState([]);
const [pathv3, setpathv3] = useState();
const [balancenative, setbalancenative] = useState(0);

const [pricemarket, setpricemarket] = useState(0);
const [swaprouter, setswaprouter] = useState('');

const [priceimpact, setpriceimpact] = useState(0);
const [spender, setspender] = useState('');
const [data0x, setdata0x] = useState('');

const [verdatos, setverdatos] = useState('');

const [ticking, setTicking] = useState(false);
const [counter, setcounter] = useState(0);

const [nametokenpay, setnametokenpay] = useState('');



const chain1 = 'https://eth.public-rpc.com';
const chain10 = 'https://rpc.ankr.com/optimism';
const chain137 = 'https://polygon-rpc.com/';
const chain42161 = 'https://arbitrum.public-rpc.com';
const chain56 = 'https://bscrpc.com';
const chain250 = 'https://rpc.ftm.tools';
const chain43114 = 'https://avalanche.public-rpc.com';


const injected = injectedModule();
const coinbaseWalletSdk = coinbaseWalletModule();


const onboard = Onboard({
  wallets: [injected, coinbaseWalletSdk],
  chains: [
    {
      id: '1',
      token: 'ETH',
      label: 'Ethereum Mainnet',
      rpcUrl: chain1
    },
    {
      id: '10',
      token: 'OPT',
      label: 'Optimist',
      rpcUrl: chain10
    },
    {
      id: '137',
      token: 'MATIC',
      label: 'Polygon',
      rpcUrl: chain137
    },
    {
      id: '42161',
      token: 'ARB',
      label: 'Arbitrum',
      rpcUrl: chain42161
    },
    {
      id: '250',
      token: 'FTM',
      label: 'Fantom',
      rpcUrl: chain250
    },
    {
      id: '43114',
      token: 'AVAX',
      label: 'Avalanche',
      rpcUrl: chain43114
    },
    {
      id: '56',
      token: 'BNB',
      label: 'BNB Smart Chain',
      rpcUrl: chain56
    }
    ],

  appMetadata: {
    name: "Web3Debit",
    icon: icono,
    logo: logowallet,
    description: "Agnostic Crypto Payment Protocol",
    recommendedInjectedWallets: [
      { name: "MetaMask", url: "https://metamask.io" },
      { name: "Coinbase", url: "https://wallet.coinbase.com/" }
    ]
   
  }

})

  useEffect(() => {


    

/*
      setbnbtokens([  
      {value: '0x0000000000000000000000000000000000000000', text: 'BNB'},
      {value: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', text: 'BUSD'},
      {value: '0x55d398326f99059fF775485246999027B3197955', text: 'USDT'},
      {value: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d', text: 'USDC'},
      {value: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', text: 'DAI'},
      {value: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', text: 'WBNB'},
      {value: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8', text: 'ETH'},
      {value: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', text: 'BTCB'},
      {value: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47', text: 'ADA'},
      {value: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82', text: 'CAKE'},
      {value: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43', text: 'DOGE'},
      {value: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE', text: 'XRP'},
    
    ]);
  */

/*
      setpolygontokens([
        {value: '0x0000000000000000000000000000000000000000', text: 'MATIC'},
        {value: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F', text: 'USDT'},
        {value: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', text: 'USDC'},
        {value: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063', text: 'DAI'},
        {value: '0xE0B52e49357Fd4DAf2c15e02058DCE6BC0057db4', text: 'agEUR'},
        {value: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270', text: 'WMATIC'},
        {value: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619', text: 'WETH'},
        {value: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6', text: 'WBTC'},
        {value: '0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F', text: 'BNB'},
        {value: '0xD6DF932A45C0f255f85145f286eA0b292B21C90B', text: 'AAVE'},
        {value: '0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3', text: 'BAL'},
        {value: '0xB0B195aEFA3650A6908f15CdaC7D92F8a5791B0B', text: 'BOB'},
        {value: '0x172370d5Cd63279eFa6d502DAB29171933a610AF', text: 'CRV'},
        {value: '0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39', text: 'LINK'},
        {value: '0xa3Fa99A148fA48D14Ed51d610c367C61876997F1', text: 'miMATIC'},
        {value: '0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a', text: 'SUSHI'},
        {value: '0xb33EaAd8d922B1083446DC23f610c2567fB5180f', text: 'UNI'},
        {value: '0xDC3326e71D45186F113a2F448984CA0e8D201995', text: 'XSGD'},
        
      ]);
*/
    
      


/*      
      setavalanchetokens([
        {value: '0x0000000000000000000000000000000000000000', text: 'AVAX'},
        {value: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7', text: 'USDT'},
        {value: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E', text: 'USDC'},
        {value: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118', text: 'USDT.e'},
        {value: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664', text: 'USDC.e'},
        {value: '0xC891EB4cbdEFf6e073e859e987815Ed1505c2ACD', text: 'EUROC'},
        {value: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7', text: 'WAVAX'},
        {value: '0x50b7545627a5162f82a992c33b87adc75187b218', text: 'WBTC.e'},
        {value: '0x152b9d0fdc40c096757f570a51e494bd4b943e50', text: 'BTC.b'},
        {value: '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab', text: 'WETH.e'},
      ]);
*/


      if (ticking) {

        const interval = setInterval(() => {
              updateCounter();
            }, 1000);
        
            return () => clearInterval(interval);
        
        }

  
  
}, [counter, ticking]);



/////////////////////////////////////////////////////////////////



useEffect(() => {


  if (theprovider?.on) {
  const handleAccountsChanged = (accounts) => {
    setaccount(accounts[0]);
    //setprovider1(new ethers.providers.Web3Provider(theprovider, "any"));
  };

  const handleChainChanged = (chainId) => {
    setchainid(chainId);

    //setprovider1(new ethers.providers.Web3Provider(theprovider, "any"));
  };


  theprovider.on("accountsChanged", handleAccountsChanged);
  theprovider.on("chainChanged", handleChainChanged);


  return () => {
    if (theprovider.removeListener) {
      theprovider.removeListener("accountsChanged", handleAccountsChanged);
      theprovider.removeListener("chainChanged", handleChainChanged);

    }
  };
}
}, [theprovider]);



function updateCounter() {

  var thecounter = counter;
  
    if (thecounter > 0) {
      thecounter = thecounter - 1;
      setcounter(thecounter);	
    
    }
  
  
    if (thecounter == 0) {
      setTicking(false);
      setroute(1);
    }
    
}






  const connectWallet = async () => {
  
    const wallets = await onboard.connectWallet();

    //console.log(wallets)
    
    if (wallets[0]) {
      // create an ethers provider with the last connected wallet provider
      
      const { accounts, chains, provider } = wallets[0];
      


      setprovider1(new Web3(provider));


      //setprovider1(new ethers.providers.Web3Provider(provider, 'any'));
      //setprovider1(new ethers.providers.Web3Provider(wallets[0].provider, 'any'));
      setisconnected(1);  
      setaccount(accounts[0].address);
      settheprovider(provider);
      setchainid(chains[0].id);



    }    
    
  }
    


  const disconnect = async () => {

    const [primaryWallet] = await onboard.state.get().wallets;
    if (primaryWallet) await onboard.disconnectWallet({ label: primaryWallet.label });

    setaccount("");
    setisconnected(0);
    setprovider1("");
    settheprovider("");
    setchainid("");


    setchainpay('');
    settokenpay('');
    setbalancetokenpay(0);
    setroute(0);
    setAmountIn(0);
    setpoolfee(0);
    setproviderprotocol('');
    setnativeToken('');
    setdecimalspay(0);
    settrade(0);  
    setamountslippage(0);
    setAmountInMax(0);
    setrouteraddress('');
    settokencontract('');
    setallowance(0); 
    setstargatefeecontract("");  
    setstargatechainid("");
    setdestpool(0);	
    setsrcpool(0);	
    setstargaterouter('');
    setgasfee(0);
    setsrcpoolbridge(0);
    settokenpaybridge('');
    setAmountInMaxBridge(0);
    setAmountInBridge(0);
    setamountslippagebridge(0);
    setrouterreceiver('');
    setdecimalsfixed(0);
    setdecimalsbridge(0);
    setamountpayfixed(0);
    setpath();
    setpathv3();
    setbalancenative(0);  
    setpricemarket(0);
    setswaprouter('');
    setpriceimpact(0);
    setspender('');
    setdata0x('');
    setverdatos('');
    setsrcpoolbridge1(0);
    settokenpaybridge1('');
    setTicking(false);
    setcounter(0);
    setnametokenpay('');
  
  }  
  


function cancel() {

window.location.reload();

}



const switchNetwork = async () => {

  //setTicking(false);
  //setcounter(0);
  

  await theprovider.request({
    method: 'wallet_switchEthereumChain',
      params: [{ chainId: Web3.utils.toHex(chainpay) }],
    });


//setroute(1);



}


const handleChainPay = async (event) => {
//const handleChainPay = event => {

  var theproviderchain;
  var thechain;

  thechain = event.target.value;
  setchainpay(event.target.value);
  settokenpay('');
  setbalancetokenpay(0);
  setroute(0);
  setAmountIn(0);
  setpoolfee(0);
  setproviderprotocol('');
  setnativeToken('');
  setdecimalspay(0);
  settrade(0);  
  setamountslippage(0);
  setAmountInMax(0);
  setrouteraddress('');
  settokencontract('');
  setallowance(0); 
  setstargatefeecontract("");  
  setstargatechainid("");
  setdestpool(0);	
  setsrcpool(0);	
  setstargaterouter('');
  setgasfee(0);
  setsrcpoolbridge(0);
  settokenpaybridge('');
  setAmountInMaxBridge(0);
  setAmountInBridge(0);
  setamountslippagebridge(0);
  setrouterreceiver('');  
  setdecimalsfixed(0);
  setdecimalsbridge(0);
  setamountpayfixed(0);
  setpath();
  setpathv3();
  setbalancenative(0);  
  setpricemarket(0);
  setswaprouter('');
  setpriceimpact(0);
  setspender('');
  setdata0x('');
  setsrcpoolbridge1(0);
  settokenpaybridge1('');
  setTicking(false);
  setcounter(0);
  setnametokenpay('');


  if (thechain == 137) {
    theproviderchain = new Web3(new Web3.providers.HttpProvider(chain137));
  }  

  if (thechain == 56) {
    theproviderchain = new Web3(new Web3.providers.HttpProvider(chain56));
  }

  if (thechain == 43114) {
    theproviderchain = new Web3(new Web3.providers.HttpProvider(chain43114));
  }



  if (Number(thechain) > 0) {
  setbalancenative(await theproviderchain.eth.getBalance(account));  
  }  


}



const handleTokenPay = async (event) => {
//const handleTokenPay = event => {

var thetokenpay = event.target.value;
var theproviderprotocol;  
var thetokenContract;
var therouteraddress;
var thesrcpool = 0;


settokenpay(thetokenpay);
setbalancetokenpay(0);
setroute(0);
setAmountIn(0);
setpoolfee(0);
setproviderprotocol('');
setnativeToken('');
setdecimalspay(0);
settrade(0);  
setamountslippage(0);
setAmountInMax(0);
setrouteraddress('');
settokencontract('');
setallowance(0); 
setstargatefeecontract("");  
setstargatechainid("");
setdestpool(0);	
setsrcpool(0);	
setstargaterouter('');
setgasfee(0);
setsrcpoolbridge(0);
settokenpaybridge('');  
setAmountInMaxBridge(0);
setAmountInBridge(0);
setamountslippagebridge(0);
setrouterreceiver('');
setdecimalsfixed(0);
setdecimalsbridge(0);
setamountpayfixed(0);
setpath();
setpathv3();
//setbalancenative(0);  
setpricemarket(0);
setswaprouter('');
setpriceimpact(0);
setspender('');
setdata0x('');
setsrcpoolbridge1(0);
settokenpaybridge1('');
setTicking(false);
setcounter(0);
setnametokenpay('');



if (thechainid == 137) {
  setstargatechainid(STARGATE_CHAIN_ID137); 
  setrouterreceiver(WEB3_ROUTER_ADDRESS137);


  if (thetoken.toLowerCase().localeCompare(('0xc2132D05D31c914a87C6611C10748AEb04B58e8F').toLowerCase()) == 0) {
    setdestpool(2);	
  }

  if (thetoken.toLowerCase().localeCompare(('0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174').toLowerCase()) == 0) {
  	setdestpool(1);
  }

} 



if (thechainid == 43114) {
  setstargatechainid(STARGATE_CHAIN_ID43114); 
  setrouterreceiver(WEB3_ROUTER_ADDRESS43114);


  if (thetoken.toLowerCase().localeCompare(('0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7').toLowerCase()) == 0) {
    setdestpool(2);	
  }

  if (thetoken.toLowerCase().localeCompare(('0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E').toLowerCase()) == 0) {
  	setdestpool(1);
  }

} 



if (thechainid == 56) {
  setstargatechainid(STARGATE_CHAIN_ID56); 
  setrouterreceiver(WEB3_ROUTER_ADDRESS56);


  if (thetoken.toLowerCase().localeCompare(('0x55d398326f99059fF775485246999027B3197955').toLowerCase()) == 0) {
    setdestpool(2);	
  }

  if (thetoken.toLowerCase().localeCompare(('0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56').toLowerCase()) == 0) {
  	setdestpool(5);
  }

} 




if (chainpay == 137) {

  theproviderprotocol = new Web3(new Web3.providers.HttpProvider(chain137));
  therouteraddress = WEB3_ROUTER_ADDRESS137;
  setproviderprotocol(theproviderprotocol);
  setrouteraddress(therouteraddress);
  setnativeToken(NATIVE137);
  setstargatefeecontract(STARGATE_FEE_CONTRACT137); 
  setstargaterouter(STARGATE_ROUTER137);
  
  if (thetokenpay.toLowerCase().localeCompare(('0xc2132D05D31c914a87C6611C10748AEb04B58e8F').toLowerCase()) == 0) {
    thesrcpool = 2;
    setsrcpool(2);	
    setdecimalsfixed(6);

    setsrcpoolbridge(1);
    settokenpaybridge('0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174');  
    setdecimalsbridge(6);
  }
  
  if (thetokenpay.toLowerCase().localeCompare(('0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174').toLowerCase()) == 0) {
    thesrcpool = 1;
    setsrcpool(1);	
    setdecimalsfixed(6);

    setsrcpoolbridge(2);
    settokenpaybridge('0xc2132D05D31c914a87C6611C10748AEb04B58e8F');  
    setdecimalsbridge(6);
  }

  
  if (thesrcpool == 0) {
    setsrcpoolbridge(2);
    settokenpaybridge('0xc2132D05D31c914a87C6611C10748AEb04B58e8F');  
    setdecimalsfixed(6);
    setdecimalsbridge(6);
    
    setsrcpoolbridge1(1);
    settokenpaybridge1('0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174');  
  }

} 



if (chainpay == 43114) {

  theproviderprotocol = new Web3(new Web3.providers.HttpProvider(chain43114));
  therouteraddress = WEB3_ROUTER_ADDRESS43114;
  setproviderprotocol(theproviderprotocol);
  setrouteraddress(therouteraddress);
  setnativeToken(NATIVE43114);
  setstargatefeecontract(STARGATE_FEE_CONTRACT43114); 
  setstargaterouter(STARGATE_ROUTER43114);
  
  if (thetokenpay.toLowerCase().localeCompare(('0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7').toLowerCase()) == 0) {
    thesrcpool = 2;
    setsrcpool(2);	
    setdecimalsfixed(6);

    setsrcpoolbridge(1);
    settokenpaybridge('0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E');  
    setdecimalsbridge(6);
  }
  
  if (thetokenpay.toLowerCase().localeCompare(('0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E').toLowerCase()) == 0) {
    thesrcpool = 1;
    setsrcpool(1);	
    setdecimalsfixed(6);

    setsrcpoolbridge(2);
    settokenpaybridge('0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7');  
    setdecimalsbridge(6);
  }

  
  if (thesrcpool == 0) {
    setsrcpoolbridge(2);
    settokenpaybridge('0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7');  
    setdecimalsfixed(6);
    setdecimalsbridge(6);

    setsrcpoolbridge1(1);
    settokenpaybridge1('0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E');  


  }

} 



if (chainpay == 56) {

  theproviderprotocol = new Web3(new Web3.providers.HttpProvider(chain56));
  therouteraddress = WEB3_ROUTER_ADDRESS56;
  setproviderprotocol(theproviderprotocol);
  setrouteraddress(therouteraddress);
  setnativeToken(NATIVE56);
  setstargatefeecontract(STARGATE_FEE_CONTRACT56); 
  setstargaterouter(STARGATE_ROUTER56);
  
  if (thetokenpay.toLowerCase().localeCompare(('0x55d398326f99059fF775485246999027B3197955').toLowerCase()) == 0) {
    thesrcpool = 2;
    setsrcpool(2);	
    setdecimalsfixed(6);

    setsrcpoolbridge(5);
    settokenpaybridge('0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56');  
    setdecimalsbridge(18);
  }
  
  if (thetokenpay.toLowerCase().localeCompare(('0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56').toLowerCase()) == 0) {
    thesrcpool = 5;
    setsrcpool(5);	
    setdecimalsfixed(6);

    setsrcpoolbridge(2);
    settokenpaybridge('0x55d398326f99059fF775485246999027B3197955');  
    setdecimalsbridge(18);
  }



  if (thesrcpool == 0) {
    setsrcpoolbridge(2);
    settokenpaybridge('0x55d398326f99059fF775485246999027B3197955');  
    setdecimalsfixed(6);
    setdecimalsbridge(18);

    setsrcpoolbridge1(5);
    settokenpaybridge1('0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56');  

  }

} 





if (Number(chainpay) > 0) {
setbalancenative(await theproviderprotocol.eth.getBalance(account));  
}


if (theproviderprotocol.utils.isAddress(thetokenpay) == true) {

  
  if (thetokenpay.toLowerCase().localeCompare('0x0000000000000000000000000000000000000000') == 0) {
    setbalancetokenpay(await theproviderprotocol.eth.getBalance(account));  
    setdecimalspay(18);
  

    if (chainpay == 137) {
      setnametokenpay('MATIC');
    }
  
    if (chainpay == 56) {
      setnametokenpay('BNB');
    }
  
    if (chainpay == 43114) {
      setnametokenpay('AVAX');
    }


  } else {
    thetokenContract = new theproviderprotocol.eth.Contract(AbiMyToken, thetokenpay);  
    setbalancetokenpay(await thetokenContract.methods.balanceOf(account).call()); 

    setnametokenpay(await thetokenContract.methods.symbol().call());

    setallowance(await thetokenContract.methods.allowance(account, therouteraddress).call()); 
    setdecimalspay(await thetokenContract.methods.decimals().call());
    settokencontract(thetokenContract);
  } 


  setroute(1);
}


}



const approveToken = async () => {

  setroute(6);
  setverdatos(true);    

  var toBN = Web3.utils.toBN;


  try{
 

    if (chainpay == 137) {



    const gasStationResponse = await fetch('https://gasstation.polygon.technology/v2');
    const gasStationObj = JSON.parse(await gasStationResponse.text());
    
    var block_number = gasStationObj.blockNumber;
  
    var base_fee = String(parseFloat(gasStationObj.estimatedBaseFee));
    var max_priority_fee = String(gasStationObj.fast.maxPriorityFee);
    
    base_fee = String(ethers.utils.parseUnits(base_fee, 'gwei'));
    max_priority_fee = String(ethers.utils.parseUnits(max_priority_fee, 'gwei'));
      
    var max_fee_per_gas = String(toBN(base_fee).add(toBN(max_priority_fee)));
    var max_fee_per_gas_rise = String((toBN(max_fee_per_gas).mul(toBN(25).mul((toBN(10)).pow(toBN(9))).div(toBN(100)))).div((toBN(10)).pow(toBN(9))));
    max_fee_per_gas = String(toBN(max_fee_per_gas).add(toBN(max_fee_per_gas_rise)));




  var gasAmount = await (new provider1.eth.Contract(AbiMyToken, tokenpay)).methods.approve(
      routeraddress,
      "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff").estimateGas({ 
      from: account});
      

  var gasAmount = Math.trunc(gasAmount * 1.25);




    const result = await (new provider1.eth.Contract(AbiMyToken, tokenpay)).methods.approve(
      routeraddress,
      "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff").send({ 
      from: account,
      gas: gasAmount,
      maxFeePerGas: max_fee_per_gas,
      maxPriorityFeePerGas: max_priority_fee })
      
         

    .once('transactionHash', function(transactionHash) {
      
      setroute(7);
           
    })





    .once('confirmation', function(confirmationNumber, receipt){


      if (receipt.status) {

      setverdatos('');    

      if (trade > 1) {
        setroute(10);
      }


      if (trade == 1) {
        setroute(4);
      }


    } else {

      setroute(8);
      setverdatos('');    

    }
    

    })
    
    .once('error', function(error, receipt){
      setroute(8);
      setverdatos('');    

    });  

  }


    }catch(e){
    setroute(8);
    setverdatos('');    
    }





    try{
 

      if (chainpay == 56 || chainpay == 43114) {


    var gasAmount = await (new provider1.eth.Contract(AbiMyToken, tokenpay)).methods.approve(
        routeraddress,
        "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff").estimateGas({ 
        from: account});
        

  var gasAmount = Math.trunc(gasAmount * 1.25);
  var theGasPrice = await provider1.eth.getGasPrice();



      const result = await (new provider1.eth.Contract(AbiMyToken, tokenpay)).methods.approve(
        routeraddress,
        "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff").send({ 
        from: account,
        gas: gasAmount,
        gasPrice: theGasPrice})
        
              
      

      .once('transactionHash', function(transactionHash) {
        
        setroute(7);
             
      })
  




      .once('confirmation', function(confirmationNumber, receipt){


        if (receipt.status) {

        setverdatos('');    

        if (trade > 1) {
          setroute(10);
        }
  
  
        if (trade == 1) {
          setroute(4);
        }
  

      } else {

        setroute(8);
        setverdatos('');    

      }
      

      })
      
      .once('error', function(error, receipt){
        setroute(8);
        setverdatos('');    
  
      });  

    }


      }catch(e){
      setroute(8);
      setverdatos('');    
      }





  }










const payPurchase = async () => {

setroute(6);
setverdatos(true);

setTicking(false);
setcounter(0);


var toBN = Web3.utils.toBN;


  try{
    if ((chainpay == 56 || chainpay == 43114) && trade == 4 && (tokenpay.toLowerCase().localeCompare('0x0000000000000000000000000000000000000000') !== 0)) {
  
      


      var dataSwap = [
        data0x,
        tokenpay,
        thetoken,
        theamountpay,
        amountInMax,      
        thestore,
        thememo,
        0,
        0   
      ];


      var dataStargate = [
        stargatechainid,
        srcpoolbridge,
        destpool,
        amountInMaxBridge,  
        amountpayfixed,
        GAS_CROSS_CHAIN,
        routerreceiver,
        tokenpaybridge
      ];



  var gasAmount = await (new provider1.eth.Contract(AbiRouter0x, routeraddress)).methods.swapAndPayCrossChainERC20(
    dataSwap,
    dataStargate).estimateGas({ 
    from: account,
    value: gasfee});
    



  gasAmount = Math.trunc(gasAmount * 1.25);
  var theGasPrice = await provider1.eth.getGasPrice();


   
      const result = await (new provider1.eth.Contract(AbiRouter0x, routeraddress)).methods.swapAndPayCrossChainERC20(
        dataSwap,
        dataStargate).send({ 
        from: account,
        value: gasfee,
        gas: gasAmount,
        gasPrice: theGasPrice})
        

      .once('transactionHash', function(transactionHash) {
        
        setroute(7);
        settrade(0);
       
      })
  

      .once('confirmation', function(confirmationNumber, receipt){

        if (receipt.status) {

        settrade(0);
        setroute(12);
  
        } else {

          setroute(8);
          settrade(0);
          setverdatos('');    

        }  


      })
      
      .once('error', function(error, receipt){
        setroute(8);
        settrade(0);
        setverdatos('');    
      });
  
  
  
    } 
  }catch(e){
    setroute(8);
    settrade(0);
    setverdatos('');    
  }







  try{
    if (chainpay == 137 && trade == 4 && (tokenpay.toLowerCase().localeCompare('0x0000000000000000000000000000000000000000') !== 0)) {
  

      const gasStationResponse = await fetch('https://gasstation.polygon.technology/v2');
      const gasStationObj = JSON.parse(await gasStationResponse.text());
      
      var block_number = gasStationObj.blockNumber;
    
      var base_fee = String(parseFloat(gasStationObj.estimatedBaseFee));
      var max_priority_fee = String(gasStationObj.fast.maxPriorityFee);
      
      base_fee = String(ethers.utils.parseUnits(base_fee, 'gwei'));
      max_priority_fee = String(ethers.utils.parseUnits(max_priority_fee, 'gwei'));
        
      var max_fee_per_gas = String(toBN(base_fee).add(toBN(max_priority_fee)));
      var max_fee_per_gas_rise = String((toBN(max_fee_per_gas).mul(toBN(25).mul((toBN(10)).pow(toBN(9))).div(toBN(100)))).div((toBN(10)).pow(toBN(9))));
      max_fee_per_gas = String(toBN(max_fee_per_gas).add(toBN(max_fee_per_gas_rise)));
      


      var dataSwap = [
        data0x,
        tokenpay,
        thetoken,
        theamountpay,
        amountInMax,      
        thestore,
        thememo,
        0,
        0   
      ];


      var dataStargate = [
        stargatechainid,
        srcpoolbridge,
        destpool,
        amountInMaxBridge,  
        amountpayfixed,
        GAS_CROSS_CHAIN,
        routerreceiver,
        tokenpaybridge
      ];



      
      var gasAmount = await (new provider1.eth.Contract(AbiRouter0x, routeraddress)).methods.swapAndPayCrossChainERC20(
        dataSwap,
        dataStargate).estimateGas({ 
        from: account,
        value: gasfee});


        gasAmount = Math.trunc(gasAmount * 1.25);

   
      const result = await (new provider1.eth.Contract(AbiRouter0x, routeraddress)).methods.swapAndPayCrossChainERC20(
        dataSwap,
        dataStargate).send({ 
        from: account,
        value: gasfee,
        gas: gasAmount,
        maxFeePerGas: max_fee_per_gas,
        maxPriorityFeePerGas: max_priority_fee })
              

      .once('transactionHash', function(transactionHash) {
        
        setroute(7);
        settrade(0);
       
      })



      .once('confirmation', function(confirmationNumber, receipt){
        
        if (receipt.status) {

        settrade(0);
        setroute(12);
  
        } else {

          setroute(8);
          settrade(0);
          setverdatos('');    

        }  


      })
      
      .once('error', function(error, receipt){
        setroute(8);
        settrade(0);
        setverdatos('');    
      });
  
  
  
    } 
  }catch(e){
    setroute(8);
    settrade(0);
    setverdatos('');    
  }
  


  try{
    if ((chainpay == 56 || chainpay == 43114) && trade == 4 && (tokenpay.toLowerCase().localeCompare('0x0000000000000000000000000000000000000000') == 0)) {

  

      var dataSwap = [
        data0x,
        nativeToken,
        thetoken,
        theamountpay,
        amountInMax,      
        thestore,
        thememo,
        0,
        0   
      ];
    
    
      var dataStargate = [
        stargatechainid,
        srcpoolbridge,
        destpool,
        amountInMaxBridge,  
        amountpayfixed,
        GAS_CROSS_CHAIN,
        routerreceiver,
        tokenpaybridge
      ];

  var totalnativetosend = String(toBN(amountInMax).add(toBN(gasfee)));	

  



  var gasAmount = await (new provider1.eth.Contract(AbiRouter0x, routeraddress)).methods.swapAndPayCrossChainNATIVE(
    dataSwap,
    dataStargate).estimateGas({ 
    from: account,
    value: totalnativetosend});




gasAmount = Math.trunc(gasAmount * 1.25);
var theGasPrice = await provider1.eth.getGasPrice();



      const result = await (new provider1.eth.Contract(AbiRouter0x, routeraddress)).methods.swapAndPayCrossChainNATIVE(
        dataSwap,
        dataStargate).send({ 
        from: account,
        value: totalnativetosend,
        gas: gasAmount,
        gasPrice: theGasPrice})
        
              


      .once('transactionHash', function(transactionHash) {
        
        setroute(7);
        settrade(0);
       
      })



      .once('confirmation', function(confirmationNumber, receipt){
        
        if (receipt.status) {

        setroute(12);
        settrade(0);

        } else {

          setroute(8);
          settrade(0);
          setverdatos('');    

        }  



      })
      
      .once('error', function(error, receipt){
        setroute(8);
        settrade(0);
        setverdatos('');    
      });
  
  
  
    } 
  }catch(e){
    setroute(8);
    settrade(0);
    setverdatos('');    
  }
 



  
  try{
    if (chainpay == 137 && trade == 4 && (tokenpay.toLowerCase().localeCompare('0x0000000000000000000000000000000000000000') == 0)) {



      const gasStationResponse = await fetch('https://gasstation.polygon.technology/v2');
      const gasStationObj = JSON.parse(await gasStationResponse.text());
      
      var block_number = gasStationObj.blockNumber;
    
      var base_fee = String(parseFloat(gasStationObj.estimatedBaseFee));
      var max_priority_fee = String(gasStationObj.fast.maxPriorityFee);
      
      base_fee = String(ethers.utils.parseUnits(base_fee, 'gwei'));
      max_priority_fee = String(ethers.utils.parseUnits(max_priority_fee, 'gwei'));
        
      var max_fee_per_gas = String(toBN(base_fee).add(toBN(max_priority_fee)));
      var max_fee_per_gas_rise = String((toBN(max_fee_per_gas).mul(toBN(25).mul((toBN(10)).pow(toBN(9))).div(toBN(100)))).div((toBN(10)).pow(toBN(9))));
      max_fee_per_gas = String(toBN(max_fee_per_gas).add(toBN(max_fee_per_gas_rise)));
      

  
  

  var dataSwap = [
    data0x,
    nativeToken,
    thetoken,
    theamountpay,
    amountInMax,      
    thestore,
    thememo,
    0,
    0   
  ];


  var dataStargate = [
    stargatechainid,
    srcpoolbridge,
    destpool,
    amountInMaxBridge,  
    amountpayfixed,
    GAS_CROSS_CHAIN,
    routerreceiver,
    tokenpaybridge
  ];


  var totalnativetosend = String(toBN(amountInMax).add(toBN(gasfee)));	


  
  var gasAmount = await (new provider1.eth.Contract(AbiRouter0x, routeraddress)).methods.swapAndPayCrossChainNATIVE(
    dataSwap,
    dataStargate).estimateGas({ 
    from: account,
    value: totalnativetosend});


    gasAmount = Math.trunc(gasAmount * 1.25);




  
      const result = await (new provider1.eth.Contract(AbiRouter0x, routeraddress)).methods.swapAndPayCrossChainNATIVE(
        dataSwap,
        dataStargate).send({ 
        from: account,
        value: totalnativetosend,
        gas: gasAmount,
        maxFeePerGas: max_fee_per_gas,
        maxPriorityFeePerGas: max_priority_fee })
              

      .once('transactionHash', function(transactionHash) {
        
        setroute(7);
        settrade(0);
       
      })



      .once('confirmation', function(confirmationNumber, receipt){
        
        if (receipt.status) {

        setroute(12);
        settrade(0);

        } else {

          setroute(8);
          settrade(0);
          setverdatos('');    

        }  


      })
      
      .once('error', function(error, receipt){
        setroute(8);
        settrade(0);
        setverdatos('');    
      });
  
  
  
    } 
  }catch(e){
    setroute(8);
    settrade(0);
    setverdatos('');    
  }
  



  try{
    if (chainpay == 137 && trade == 3) {
    

      const gasStationResponse = await fetch('https://gasstation.polygon.technology/v2');
      const gasStationObj = JSON.parse(await gasStationResponse.text());
      
      var block_number = gasStationObj.blockNumber;
    
      var base_fee = String(parseFloat(gasStationObj.estimatedBaseFee));
      var max_priority_fee = String(gasStationObj.fast.maxPriorityFee);
      
      base_fee = String(ethers.utils.parseUnits(base_fee, 'gwei'));
      max_priority_fee = String(ethers.utils.parseUnits(max_priority_fee, 'gwei'));
        
      var max_fee_per_gas = String(toBN(base_fee).add(toBN(max_priority_fee)));
      var max_fee_per_gas_rise = String((toBN(max_fee_per_gas).mul(toBN(25).mul((toBN(10)).pow(toBN(9))).div(toBN(100)))).div((toBN(10)).pow(toBN(9))));
      max_fee_per_gas = String(toBN(max_fee_per_gas).add(toBN(max_fee_per_gas_rise)));
      


      

      var dataSwap = [
        '0x',
        tokenpay,
        thetoken,
        theamountpay,
        amountInMax, 
        thestore,
        thememo,
        amountInMax, 
        amountInMax
      ];


      var dataStargate = [
        stargatechainid,
        srcpool,
        destpool,
        amountInMax,      
        amountpayfixed,
        GAS_CROSS_CHAIN,
        routerreceiver,
        tokenpay,
      ];



      var gasAmount = await (new provider1.eth.Contract(AbiRouter0x, routeraddress)).methods.swapToStargate(
        dataSwap,
        dataStargate).estimateGas({ 
        from: account,
        value: gasfee});


        gasAmount = Math.trunc(gasAmount * 1.25);




      const result = await (new provider1.eth.Contract(AbiRouter0x, routeraddress)).methods.swapToStargate(
        dataSwap,
        dataStargate).send({ 
        from: account,
        value: gasfee,
        gas: gasAmount,
        maxFeePerGas: max_fee_per_gas,
        maxPriorityFeePerGas: max_priority_fee })
              

      .once('transactionHash', function(transactionHash) {
        
        setroute(7);
        settrade(0);
       
      })



      .once('confirmation', function(confirmationNumber, receipt){
        
        if (receipt.status) {

        setroute(12);
        settrade(0);

        } else {

          setroute(8);
          settrade(0);
          setverdatos('');    

        }


      })
      
      .once('error', function(error, receipt){
        setroute(8);
        settrade(0);
        setverdatos('');    
      });
  
  
  
  
    } 
  }catch(e){
    setroute(8);
    settrade(0);
    setverdatos('');    
  }
  





  try{
    if ((chainpay == 56 || chainpay == 43114) && trade == 3) {
    

      var dataSwap = [
        '0x',
        tokenpay,
        thetoken,
        theamountpay,
        amountInMax, 
        thestore,
        thememo,
        amountInMax, 
        amountInMax
      ];


      var dataStargate = [
        stargatechainid,
        srcpool,
        destpool,
        amountInMax,      
        amountpayfixed,
        GAS_CROSS_CHAIN,
        routerreceiver,
        tokenpay,
      ];




      var gasAmount = await (new provider1.eth.Contract(AbiRouter0x, routeraddress)).methods.swapToStargate(
        dataSwap,
        dataStargate).estimateGas({ 
        from: account,
        value: gasfee});
        



      gasAmount = Math.trunc(gasAmount * 1.25);
      var theGasPrice = await provider1.eth.getGasPrice();
  




      const result = await (new provider1.eth.Contract(AbiRouter0x, routeraddress)).methods.swapToStargate(
        dataSwap,
        dataStargate).send({ 
        from: account,
        value: gasfee,
        gas: gasAmount,
        gasPrice: theGasPrice})
        
              


      .once('transactionHash', function(transactionHash) {
        
        setroute(7);
        settrade(0);
       
      })



      .once('confirmation', function(confirmationNumber, receipt){
        
        if (receipt.status) {

        setroute(12);
        settrade(0);

        } else {

          setroute(8);
          settrade(0);
          setverdatos('');    


        }  


      })
      
      .once('error', function(error, receipt){
        setroute(8);
        settrade(0);
        setverdatos('');    
      });
  
  
  
  
    } 
  }catch(e){
    setroute(8);
    settrade(0);
    setverdatos('');    
  }
  
  


  try{
    if (chainpay == 137 && trade == 1) {
      
  
  const gasStationResponse = await fetch('https://gasstation.polygon.technology/v2');
  const gasStationObj = JSON.parse(await gasStationResponse.text());
  
  var block_number = gasStationObj.blockNumber;

  var base_fee = String(parseFloat(gasStationObj.estimatedBaseFee));
  var max_priority_fee = String(gasStationObj.fast.maxPriorityFee);
  
  base_fee = String(ethers.utils.parseUnits(base_fee, 'gwei'));
  max_priority_fee = String(ethers.utils.parseUnits(max_priority_fee, 'gwei'));
    
  var max_fee_per_gas = String(toBN(base_fee).add(toBN(max_priority_fee)));
  var max_fee_per_gas_rise = String((toBN(max_fee_per_gas).mul(toBN(25).mul((toBN(10)).pow(toBN(9))).div(toBN(100)))).div((toBN(10)).pow(toBN(9))));
  max_fee_per_gas = String(toBN(max_fee_per_gas).add(toBN(max_fee_per_gas_rise)));

  

  

      var dataSwap = [
        '0x',
        tokenpay,
        tokenpay,
        theamountpay,
        theamountpay,
        thestore,
        thememo,
        theamountpay,
        theamountpay
      ];
    

      var gasAmount = await (new provider1.eth.Contract(AbiRouter0x, routeraddress)).methods.noSwapPayOnChainSameERC20(
        dataSwap).estimateGas({ 
        from: account});


        gasAmount = Math.trunc(gasAmount * 1.25);




      const result = await (new provider1.eth.Contract(AbiRouter0x, routeraddress)).methods.noSwapPayOnChainSameERC20(
        dataSwap).send({ 
        from: account,
        gas: gasAmount,
        maxFeePerGas: max_fee_per_gas,
        maxPriorityFeePerGas: max_priority_fee })
              

      .once('transactionHash', function(transactionHash) {
        
        setroute(7);
        settrade(0);
       
      })


      .once('confirmation', function(confirmationNumber, receipt){
        
        if (receipt.status) {

        setroute(12);
        settrade(0);
  
        } else {

          setroute(8);
          settrade(0);
          setverdatos('');    

        }  

      })
      
      .once('error', function(error, receipt){
        setroute(8);
        settrade(0);
        setverdatos('');    
      });
  
  
  
  
    } 
  }catch(e){
    setroute(8);
    settrade(0);
    setverdatos('');    

  }
  









  try{
  if ((chainpay == 56 || chainpay == 43114) && trade == 1) {


    var dataSwap = [
      '0x',
      tokenpay,
      tokenpay,
      theamountpay,
      theamountpay,
      thestore,
      thememo,
      theamountpay,
      theamountpay
    ];


    var gasAmount = await (new provider1.eth.Contract(AbiRouter0x, routeraddress)).methods.noSwapPayOnChainSameERC20(
      dataSwap).estimateGas({ 
      from: account});
      

    

    gasAmount = Math.trunc(gasAmount * 1.25);
    var theGasPrice = await provider1.eth.getGasPrice();



    const result = await (new provider1.eth.Contract(AbiRouter0x, routeraddress)).methods.noSwapPayOnChainSameERC20(
      dataSwap).send({ 
      from: account,
      gas: gasAmount,
      gasPrice: theGasPrice})
      


    .once('transactionHash', function(transactionHash) {
        
      setroute(7);
      settrade(0);
     
    })

    
    .once('confirmation', function(confirmationNumber, receipt){
      

      if (receipt.status) {

      setroute(12);
      settrade(0);

      } else {

        setroute(8);
        settrade(0);
        setverdatos('');    

      }  


    })
    
    .once('error', function(error, receipt){
      setroute(8);
      settrade(0);
      setverdatos('');    
    });




  } 
}catch(e){
  setroute(8);
  settrade(0);
  setverdatos('');    
}



try{
  if ((chainpay == 56 || chainpay == 43114) && trade == 2 && (tokenpay.toLowerCase().localeCompare('0x0000000000000000000000000000000000000000') !== 0)) {


    

    var dataSwap = [
      data0x,
      tokenpay,
      thetoken,
      theamountpay,
      amountInMax,
      thestore,
      thememo,
      0,
      0
    ];





      var gasAmount = await (new provider1.eth.Contract(AbiRouter0x, routeraddress)).methods.swapAndPayOnChainERC20(
      dataSwap).estimateGas({ 
      from: account});


      gasAmount = Math.trunc(gasAmount * 1.25);

      var theGasPrice = await provider1.eth.getGasPrice();



    const result = await (new provider1.eth.Contract(AbiRouter0x, routeraddress)).methods.swapAndPayOnChainERC20(
      
      dataSwap).send({ 
      from: account,
      gas: gasAmount,
      gasPrice: theGasPrice})
    
            



    .once('transactionHash', function(transactionHash) {
        
      setroute(7);
      settrade(0);
     
    })


    .once('confirmation', function(confirmationNumber, receipt){
      

      if (receipt.status) {

      setroute(12);
      settrade(0);

      } else {

        setroute(8);
        settrade(0);
        setverdatos('');    

      }  



    })
    
    .once('error', function(error, receipt){
      setroute(8);
      settrade(0);
      setverdatos('');    
    });



  } 
}catch(e){
  setroute(8);
  settrade(0);
  setverdatos('');    
}



try{
  if (chainpay == 137 && trade == 2 && (tokenpay.toLowerCase().localeCompare('0x0000000000000000000000000000000000000000') !== 0)) {

    
    const gasStationResponse = await fetch('https://gasstation.polygon.technology/v2');
    const gasStationObj = JSON.parse(await gasStationResponse.text());
    
    var block_number = gasStationObj.blockNumber;
  
    var base_fee = String(parseFloat(gasStationObj.estimatedBaseFee));
    var max_priority_fee = String(gasStationObj.fast.maxPriorityFee);
    
    base_fee = String(ethers.utils.parseUnits(base_fee, 'gwei'));
    max_priority_fee = String(ethers.utils.parseUnits(max_priority_fee, 'gwei'));
      
    var max_fee_per_gas = String(toBN(base_fee).add(toBN(max_priority_fee)));
    var max_fee_per_gas_rise = String((toBN(max_fee_per_gas).mul(toBN(25).mul((toBN(10)).pow(toBN(9))).div(toBN(100)))).div((toBN(10)).pow(toBN(9))));
    max_fee_per_gas = String(toBN(max_fee_per_gas).add(toBN(max_fee_per_gas_rise)));
  

      
      

      var dataSwap = [
        data0x,
        tokenpay,
        thetoken,
        theamountpay,
        amountInMax,
        thestore,
        thememo,
        0,
        0
      ];
  
  
      var gasAmount = await (new provider1.eth.Contract(AbiRouter0x, routeraddress)).methods.swapAndPayOnChainERC20(
        dataSwap).estimateGas({ 
        from: account});
  

        gasAmount = Math.trunc(gasAmount * 1.25);



    const result = await (new provider1.eth.Contract(AbiRouter0x, routeraddress)).methods.swapAndPayOnChainERC20(
      dataSwap).send({ 
      from: account,
      gas: gasAmount,
      maxFeePerGas: max_fee_per_gas,
      maxPriorityFeePerGas: max_priority_fee })
            


    .once('transactionHash', function(transactionHash) {
        
      setroute(7);
      settrade(0);
     
    })


    .once('confirmation', function(confirmationNumber, receipt){
      

      if (receipt.status) {

      setroute(12);
      settrade(0);

      } else {

        setroute(8);
        settrade(0);
        setverdatos('');    

      }  



    })
    
    .once('error', function(error, receipt){
      setroute(8);
      settrade(0);
      setverdatos('');    
    });



  } 
}catch(e){
  setroute(8);
  settrade(0);
  setverdatos('');    
}



try{
  if ((chainpay == 56 || chainpay == 43114) && trade == 2 && (tokenpay.toLowerCase().localeCompare('0x0000000000000000000000000000000000000000') == 0)) {





    var dataSwap = [
      data0x,
      nativeToken,
      thetoken,
      theamountpay,
      amountInMax,
      thestore,
      thememo,
      0,
      0
    ];





    var gasAmount = await (new provider1.eth.Contract(AbiRouter0x, routeraddress)).methods.swapAndPayOnChainNATIVE(
      dataSwap).estimateGas({ 
      from: account,
      value: amountInMax});

      
      gasAmount = Math.trunc(gasAmount * 1.25);

      var theGasPrice = await provider1.eth.getGasPrice();


    const result = await (new provider1.eth.Contract(AbiRouter0x, routeraddress)).methods.swapAndPayOnChainNATIVE(
      dataSwap).send({ 
      from: account,
      value: amountInMax,
      gas: gasAmount,
      gasPrice: theGasPrice})

            

    .once('transactionHash', function(transactionHash) {
        
      setroute(7);
      settrade(0);
     
    })



    .once('confirmation', function(confirmationNumber, receipt){

      if (receipt.status) {

      setroute(12);
      settrade(0);

      } else {

        setroute(8);
        settrade(0);   
        setverdatos('');    

      }  



    })
    
    .once('error', function(error, receipt){
      setroute(8);
      settrade(0);
      setverdatos('');    
    });

  } 

}catch(e){
  setroute(8);
  settrade(0);
  setverdatos('');    
}









  try{
  if (chainpay == 137 && trade == 2 && (tokenpay.toLowerCase().localeCompare('0x0000000000000000000000000000000000000000') == 0)) {



    
    const gasStationResponse = await fetch('https://gasstation.polygon.technology/v2');
    const gasStationObj = JSON.parse(await gasStationResponse.text());
    
    var block_number = gasStationObj.blockNumber;
  
    var base_fee = String(parseFloat(gasStationObj.estimatedBaseFee));
    var max_priority_fee = String(gasStationObj.fast.maxPriorityFee);
    
    base_fee = String(ethers.utils.parseUnits(base_fee, 'gwei'));
    max_priority_fee = String(ethers.utils.parseUnits(max_priority_fee, 'gwei'));
      
    var max_fee_per_gas = String(toBN(base_fee).add(toBN(max_priority_fee)));
    var max_fee_per_gas_rise = String((toBN(max_fee_per_gas).mul(toBN(25).mul((toBN(10)).pow(toBN(9))).div(toBN(100)))).div((toBN(10)).pow(toBN(9))));
    max_fee_per_gas = String(toBN(max_fee_per_gas).add(toBN(max_fee_per_gas_rise)));
  






    var dataSwap = [
      data0x,
      nativeToken,
      thetoken,
      theamountpay,
      amountInMax,
      thestore,
      thememo,
      0,
      0
    ];



    var gasAmount = await (new provider1.eth.Contract(AbiRouter0x, routeraddress)).methods.swapAndPayOnChainNATIVE(
      dataSwap).estimateGas({ 
      from: account,
      value: amountInMax});


      gasAmount = Math.trunc(gasAmount * 1.25);



    const result = await (new provider1.eth.Contract(AbiRouter0x, routeraddress)).methods.swapAndPayOnChainNATIVE(
      dataSwap).send({ 
      from: account,
      value: amountInMax,
      gas: gasAmount,
      maxFeePerGas: max_fee_per_gas,
      maxPriorityFeePerGas: max_priority_fee })
            

    .once('transactionHash', function(transactionHash) {
        
      setroute(7);
      settrade(0);
     
    })


    .once('confirmation', function(confirmationNumber, receipt){
      
      if (receipt.status) {

      setroute(12);
      settrade(0);

      } else {

        setroute(8);
        settrade(0);   
        setverdatos('');    

      }  



    })
    
    .once('error', function(error, receipt){
      setroute(8);
      settrade(0);
      setverdatos('');    
    });

  } 

}catch(e){
  setroute(8);
  settrade(0);
  setverdatos('');    
  
}

}



function getQuotation() {

  if (Number(chainpay) == Number(thechainid)) {  
    getQuotationOnChain();
  }

  if (Number(chainpay) != Number(thechainid)) {  
    getQuotationCrossChain();
  }


}


const getQuotationCrossChain = async () => {

  var quotation = 0;
  
  var thebalance = 0; 
  
  var thetokenpay;
  var toBN;
  var theallowance = 0;

  settrade(0);


  var swapObj;
  var feeContract;		
  var gasContract;		
  var crosscheck = 0;	
  var theparams = "";
  var thegasfee = 0;		
  var quotegas = [];  
  var quotationbridge = 0;
  var theamountpaybridge = 0;
  var gasbalance = 0;
  var totalin = 0;
  var theamountpayfixed;
  var theslippage;
  var theslippagebridge;
  var changebridgetoken = 0;
  var changebridgetokenswap = 0;


  toBN = providerprotocol.utils.toBN;

  feeContract = new providerprotocol.eth.Contract(AbiFee, stargatefeecontract);		
  gasContract = new providerprotocol.eth.Contract(AbiStargate, stargaterouter);		  



  setbalancenative(await providerprotocol.eth.getBalance(account));  

    

  
  if (tokenpay.toLowerCase().localeCompare('0x0000000000000000000000000000000000000000') == 0) {
  thetokenpay = nativeToken;
  thebalance = await providerprotocol.eth.getBalance(account);  
  setbalancetokenpay(thebalance);  

  /*
  if (chainpay == 137) {
    setnametokenpay('MATIC');
  }

  if (chainpay == 56) {
    setnametokenpay('BNB');
  }

  if (chainpay == 43114) {
    setnametokenpay('AVAX');
  }
*/

} else {

  thetokenpay = tokenpay;
  thebalance = await tokencontract.methods.balanceOf(account).call(); 
  theallowance = await tokencontract.methods.allowance(account, routeraddress).call(); 
  setbalancetokenpay(thebalance);
  setallowance(theallowance);

  //setnametokenpay(await tokencontract.methods.symbol().call());

}  

setroute(2);



if (Number(srcpool) > 0) {



settrade(3);


if (Number(thedecimalstoken) == Number(decimalsfixed)) {
theamountpayfixed = String(theamountpay);
//setamountpayfixed(theamountpayfixed);
}


if (Number(thedecimalstoken) !== Number(decimalsfixed)) {
  var decimalsdif = 0;

  if (Number(thedecimalstoken) > Number(decimalsfixed)) {
    decimalsdif = thedecimalstoken - decimalsfixed;
    theamountpayfixed = toBN(theamountpay).div((toBN(10)).pow(toBN(decimalsdif)));
    //setamountpayfixed(theamountpayfixed);
  }

  if (Number(thedecimalstoken) < Number(decimalsfixed)) {
    decimalsdif = decimalsfixed - thedecimalstoken;
    theamountpayfixed = toBN(theamountpay).mul((toBN(10)).pow(toBN(decimalsdif)));
    
    //theamountpayfixed = ethers.utils.formatUnits(theamountpay, thedecimalstoken);
    //theamountpayfixed = ethers.utils.parseUnits(theamountpayfixed, decimalsfixed);
    //setamountpayfixed(theamountpayfixed);
  }

}



try{
swapObj = await feeContract.methods.getFees(
      srcpool,
      destpool,
      stargatechainid,
      account,
      theamountpayfixed,
    ).call()
    }catch(e){ 
      crosscheck = 1;
    };


if (crosscheck == 1) {
//setroute(3);
changebridgetoken = 1;
crosscheck = 0;

} else {

  var estfeestargate = String((toBN(theamountpayfixed).mul(toBN(MAXFEESTARGATE).mul((toBN(10)).pow(toBN(decimalsfixed))).div(toBN(10000)))).div((toBN(10)).pow(toBN(decimalsfixed))));

  var thefeestargate = toBN(swapObj.eqFee);
  thefeestargate = toBN(thefeestargate).add(toBN(swapObj.protocolFee));
  thefeestargate = toBN(thefeestargate).add(toBN(swapObj.lpFee));
  thefeestargate = toBN(thefeestargate).sub(toBN(swapObj.eqReward));

if (toBN(thefeestargate).gt(toBN(estfeestargate))) {
  crosscheck = 1
}

if (crosscheck == 1) {
  //setroute(3);
  changebridgetoken = 1;
  crosscheck = 0;
} else {
  


/////////////////////////////////////// CALCULAR GAS PARA CROSS CHAIN //////////////////

quotation = toBN(theamountpayfixed).add(toBN(swapObj.eqFee));
quotation = toBN(quotation).add(toBN(swapObj.protocolFee));
quotation = toBN(quotation).add(toBN(swapObj.lpFee));
quotation = toBN(quotation).sub(toBN(swapObj.eqReward));

theslippage = String((toBN(quotation).mul(toBN(SLIPPAGESTARGATE).mul((toBN(10)).pow(toBN(decimalsfixed))).div(toBN(10000)))).div((toBN(10)).pow(toBN(decimalsfixed))));


if (Number(decimalspay) !== Number(decimalsfixed)) {
  var decimalsdif = 0;

  if (Number(decimalspay) < Number(decimalsfixed)) {
    decimalsdif = decimalsfixed - decimalspay;
    quotation = toBN(quotation).div((toBN(10)).pow(toBN(decimalsdif)));
    theslippage = toBN(theslippage).div((toBN(10)).pow(toBN(decimalsdif)));
  }

  if (Number(decimalspay) > Number(decimalsfixed)) {
    decimalsdif = decimalspay - decimalsfixed;
    
    quotation = toBN(quotation).mul((toBN(10)).pow(toBN(decimalsdif)));
    theslippage = toBN(theslippage).mul((toBN(10)).pow(toBN(decimalsdif)));
  
  }

}



setamountslippage(String(theslippage));
setAmountIn(String(quotation));
setAmountInMax(String(toBN(quotation).add(toBN(theslippage))));

totalin = String(toBN(quotation).add(toBN(theslippage)));


var encoder = ethers.utils.defaultAbiCoder;         

var theparams = encoder.encode([ "uint", "uint", "address", "uint", "address" ], [ '1000', chainpay, thestore, theamountpay, account ]);


try{
quotegas = await gasContract.methods.quoteLayerZeroFee(
    stargatechainid,                 			// destination chainId
    1,             			  		// function type: see Bridge.sol for all types
    routerreceiver,                  // destination of tokens
    theparams,                 // payload, using abi.encode()
    ({
        dstGasForCall: GAS_CROSS_CHAIN,       // extra gas, if calling smart contract,
        dstNativeAmount: 0,     // amount of dust dropped in destination wallet 
        dstNativeAddr: '0x' // destination wallet for dust
    })
    ).call()
  }catch(e){
   crosscheck = 1;

  };

if (crosscheck == 1) {
setroute(3);


} else {


thegasfee = String(toBN(quotegas[0]).mul(toBN(2)));
setgasfee(String(thegasfee));



if (Number(thedecimalstoken) == Number(decimalspay)) {
  theamountpayfixed = String(theamountpay);
  setamountpayfixed(theamountpayfixed);
  }
  
  
  if (Number(thedecimalstoken) !== Number(decimalspay)) {
    var decimalsdif = 0;
  
    if (Number(thedecimalstoken) > Number(decimalspay)) {
      decimalsdif = thedecimalstoken - decimalspay;
      theamountpayfixed = toBN(theamountpay).div((toBN(10)).pow(toBN(decimalsdif)));
      theamountpayfixed = toBN(theamountpayfixed).add(toBN(1));
      setamountpayfixed(String(theamountpayfixed));
    }
  
    if (Number(thedecimalstoken) < Number(decimalspay)) {
      decimalsdif = decimalspay - thedecimalstoken;
      theamountpayfixed = toBN(theamountpay).mul((toBN(10)).pow(toBN(decimalsdif)));
      setamountpayfixed(String(theamountpayfixed));
    }
  
  }
  




//////////////////////////////////////////////////////////


gasbalance = await providerprotocol.eth.getBalance(account);  


var quotecurrency = theamountpay;
  var basecurrency = quotation;
  
  
  if (Number(decimalspay) > 18) {
    basecurrency = toBN(basecurrency).div((toBN(10)).pow(toBN(decimalspay - 18)));
  
  }
  
  if (Number(decimalspay) < 18) {
    basecurrency = toBN(basecurrency).mul((toBN(10)).pow(toBN(18 - decimalspay)));
  
  }
  
  if (Number(thedecimalstoken) > 18) {
    quotecurrency = toBN(quotecurrency).div((toBN(10)).pow(toBN(thedecimalstoken - 18)));
  
  }
  
  if (Number(thedecimalstoken) < 18) {
    quotecurrency = toBN(quotecurrency).mul((toBN(10)).pow(toBN(18 - thedecimalstoken)));
  
  }
  
  
  var thepricemarket = (toBN(quotecurrency).mul(toBN(1000000)).div(toBN(basecurrency))) / 1000000;
  setpricemarket(String(thepricemarket));


  if ((toBN(thebalance).gt((toBN(quotation).add(toBN(theslippage)))) || toBN(thebalance).eq((toBN(quotation).add(toBN(theslippage))))) && (toBN(gasbalance).gt(toBN(thegasfee)) || toBN(gasbalance).eq(toBN(thegasfee)))) {

  //if (toBN(thebalance).gt((toBN(quotation).add(toBN(theslippage)))) && toBN(gasbalance).gt(toBN(thegasfee))) {
          
	  if (toBN(theallowance).lt((toBN(quotation).add(toBN(theslippage))))) {
        setroute(9);
    } else {
        setroute(4);  

    
        setTicking(true);
        setcounter(COUNTERDELAY);
    

	  }   
  
    
  } else {  
      setroute(5);  
  }




   }	
  }
 }
}


if (Number(srcpool) == 0 || (Number(srcpool) > 0 && changebridgetoken == 1)) {

  settrade(4);

  
  
  if (Number(thedecimalstoken) == Number(decimalsfixed)) {
    theamountpayfixed = String(theamountpay);
    //setamountpayfixed(theamountpayfixed);
    }
    
    
    if (Number(thedecimalstoken) !== Number(decimalsfixed)) {
      var decimalsdif = 0;
    
      if (Number(thedecimalstoken) > Number(decimalsfixed)) {
        decimalsdif = thedecimalstoken - decimalsfixed;
        theamountpayfixed = toBN(theamountpay).div((toBN(10)).pow(toBN(decimalsdif)));
        //setamountpayfixed(theamountpayfixed);
      }
    
      if (Number(thedecimalstoken) < Number(decimalsfixed)) {
        decimalsdif = decimalsfixed - thedecimalstoken;
        theamountpayfixed = toBN(theamountpay).mul((toBN(10)).pow(toBN(decimalsdif)));
        
        //theamountpayfixed = ethers.utils.formatUnits(theamountpay, thedecimalstoken);
        //theamountpayfixed = ethers.utils.parseUnits(theamountpayfixed, decimalsfixed);
        //setamountpayfixed(theamountpayfixed);
      }
    
    }
    
    
  
  try{
  swapObj = await feeContract.methods.getFees(
        srcpoolbridge,
        destpool,
        stargatechainid,
        account,
        theamountpayfixed,
      ).call()
      }catch(e){ 
      crosscheck = 1;
      
    };
  
  
  
  if (crosscheck == 1) {
  
  if (srcpool > 0) {
    setroute(3);
  }

  if (srcpool == 0) {
    changebridgetokenswap = 1;
    crosscheck = 0;
  }


  } else {
  
  
    var estfeestargate = String((toBN(theamountpayfixed).mul(toBN(MAXFEESTARGATE).mul((toBN(10)).pow(toBN(decimalsfixed))).div(toBN(10000)))).div((toBN(10)).pow(toBN(decimalsfixed))));

    var thefeestargate = toBN(swapObj.eqFee);
    thefeestargate = toBN(thefeestargate).add(toBN(swapObj.protocolFee));
    thefeestargate = toBN(thefeestargate).add(toBN(swapObj.lpFee));
    thefeestargate = toBN(thefeestargate).sub(toBN(swapObj.eqReward));
  
  if (toBN(thefeestargate).gt(toBN(estfeestargate))) {
    crosscheck = 1
  }
  
  if (crosscheck == 1) {
    
  if (srcpool > 0) {
      setroute(3);
  }  
  
  if (srcpool == 0) {
    changebridgetokenswap = 1;
    crosscheck = 0;
  }
  
  
  
  
  } else {
  

    quotationbridge = toBN(theamountpayfixed).add(toBN(swapObj.eqFee));
    quotationbridge = toBN(quotationbridge).add(toBN(swapObj.protocolFee));
    quotationbridge = toBN(quotationbridge).add(toBN(swapObj.lpFee));
    quotationbridge = toBN(quotationbridge).sub(toBN(swapObj.eqReward));
    
    theslippagebridge = String((toBN(quotationbridge).mul(toBN(SLIPPAGESTARGATE).mul((toBN(10)).pow(toBN(decimalsfixed))).div(toBN(10000)))).div((toBN(10)).pow(toBN(decimalsfixed))));
    


    if (Number(decimalsbridge) !== Number(decimalsfixed)) {
      var decimalsdif = 0;
    
      if (Number(decimalsbridge) < Number(decimalsfixed)) {
        decimalsdif = decimalsfixed - decimalsbridge;
        quotationbridge = toBN(quotationbridge).div((toBN(10)).pow(toBN(decimalsdif)));
        theslippagebridge = toBN(theslippagebridge).div((toBN(10)).pow(toBN(decimalsdif)));
      }
    
      if (Number(decimalsbridge) > Number(decimalsfixed)) {
        decimalsdif = decimalsbridge - decimalsfixed;
        
        quotationbridge = toBN(quotationbridge).mul((toBN(10)).pow(toBN(decimalsdif)));
        theslippagebridge = toBN(theslippagebridge).mul((toBN(10)).pow(toBN(decimalsdif)));
      }
    
    }
    

    setamountslippagebridge(theslippagebridge);
    setAmountInBridge(String(quotationbridge));
    
    theamountpaybridge = String(toBN(quotationbridge).add(toBN(theslippagebridge)));
    setAmountInMaxBridge(String(theamountpaybridge));
    
  
    /////////////////////////////////////// CALCULAR GAS PARA CROSS CHAIN //////////////////

    

    var encoder = ethers.utils.defaultAbiCoder;         

    var theparams = encoder.encode([ "uint", "uint", "address", "uint", "address" ], [ '1000', chainpay, thestore, theamountpay, account ]);


  
  try{
  quotegas = await gasContract.methods.quoteLayerZeroFee(
      stargatechainid,                 			// destination chainId
      1,             			  		// function type: see Bridge.sol for all types
      routerreceiver,                  // destination of tokens
      theparams,                 // payload, using abi.encode()
      ({
          dstGasForCall: GAS_CROSS_CHAIN,       // extra gas, if calling smart contract,
          dstNativeAmount: 0,     // amount of dust dropped in destination wallet 
          dstNativeAddr: '0x' // destination wallet for dust
      })
      ).call()
    }catch(e){
     crosscheck = 1;
  
    };
  
  if (crosscheck == 1) {
  setroute(3);
  
  
  } else {
  

  thegasfee = String(toBN(quotegas[0]).mul(toBN(2)));
  setgasfee(String(thegasfee));

  


  if (Number(thedecimalstoken) == Number(decimalsbridge)) {
    theamountpayfixed = String(theamountpay);
    setamountpayfixed(theamountpayfixed);
    }
    
    
    if (Number(thedecimalstoken) !== Number(decimalsbridge)) {
      var decimalsdif = 0;
    
      if (Number(thedecimalstoken) > Number(decimalsbridge)) {
        decimalsdif = thedecimalstoken - decimalsbridge;
        theamountpayfixed = toBN(theamountpay).div((toBN(10)).pow(toBN(decimalsdif)));
        theamountpayfixed = toBN(theamountpayfixed).add(toBN(1));
        setamountpayfixed(String(theamountpayfixed));
      }
    
      if (Number(thedecimalstoken) < Number(decimalsbridge)) {
        decimalsdif = decimalsbridge - thedecimalstoken;
        theamountpayfixed = toBN(theamountpay).mul((toBN(10)).pow(toBN(decimalsdif)));
        setamountpayfixed(String(theamountpayfixed));
      }
    
    }
  



  

  


    var apiQuoteResponse;
    var quoteObj;
    var tokenpay0x = '';
    var AmountInMax0x = 0;
    
    if (tokenpay.toLowerCase().localeCompare('0x0000000000000000000000000000000000000000') == 0) {
      tokenpay0x = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';
    } else {
      tokenpay0x = thetokenpay;
    }  
    


    const params = {
    
      buyToken: tokenpaybridge,
      sellToken: tokenpay0x,
      buyAmount: theamountpaybridge,
      slippagePercentage: SLIPPAGE0X / 10000,
      enableSlippageProtection: true,
      affiliateAddress: '0x00a1AA06490F5BB30388FD3d3d337BF4c8D13DCD',
    };
        
    
    var stringdata = qs.stringify(params);



    
      if (Number(chainpay) == 137) {
    

        apiQuoteResponse = await fetch('https://polygon.api.0x.org/swap/v1/quote?' + stringdata , {
        headers: {
        '0x-api-key': 'f94e4580-f9df-400c-aecf-6582e1c18aab'}
       });



/*    
          apiQuoteResponse = await fetch('https://polygon.api.0x.org/swap/v1/quote?' + stringdata , 
          {header: '0x-api-key: f94e4580-f9df-400c-aecf-6582e1c18aab',
         });
  */    
    
    
          quoteObj = JSON.parse(await apiQuoteResponse.text());
    
      }  
    

      if (Number(chainpay) == 43114) {
    
        
        apiQuoteResponse = await fetch('https://avalanche.api.0x.org/swap/v1/quote?' + stringdata , {
        headers: {
        '0x-api-key': 'f94e4580-f9df-400c-aecf-6582e1c18aab'}
       });
    


/*
        apiQuoteResponse = await fetch('https://avalanche.api.0x.org/swap/v1/quote?' + stringdata , 
        {header: '0x-api-key: f94e4580-f9df-400c-aecf-6582e1c18aab',
       });
*/    
  
  
        quoteObj = JSON.parse(await apiQuoteResponse.text());
  
    }  
  




      if (Number(chainpay) == 56) {
    
      
      apiQuoteResponse = await fetch('https://bsc.api.0x.org/swap/v1/quote?' + stringdata , {
      headers: {
      '0x-api-key': 'f94e4580-f9df-400c-aecf-6582e1c18aab'}
       });
      


      /*  
        apiQuoteResponse = await fetch('https://bsc.api.0x.org/swap/v1/quote?' + stringdata , 
        {header: '0x-api-key: f94e4580-f9df-400c-aecf-6582e1c18aab',
       });
      
      */ 
        
        quoteObj = JSON.parse(await apiQuoteResponse.text());
    
      }  

      



    
    if (quoteObj.code == undefined) {
    
          quotation = quoteObj.sellAmount; 
          setswaprouter(quoteObj.to);
          setpriceimpact(quoteObj.estimatedPriceImpact);
          setspender(quoteObj.allowanceTarget);
          setdata0x(quoteObj.data);

      } else {
        quotation = 0; 
      }      
     

  
  
    if (toBN(quotation).eq(toBN(0))) {
      setroute(3);
    } else {
      
  // Calculate Slippage
  


  if (tokenpay.toLowerCase().localeCompare('0x0000000000000000000000000000000000000000') !== 0) {
    theslippage = String((toBN(quotation).mul(toBN(SLIPPAGE).mul((toBN(10)).pow(toBN(decimalspay))).div(toBN(10000)))).div((toBN(10)).pow(toBN(decimalspay))));
    setamountslippage(theslippage);
    setAmountInMax(String(toBN(quotation).add(toBN(theslippage))));
    AmountInMax0x = String(toBN(quotation).add(toBN(theslippage)));
  } else {
    setAmountInMax(String(quoteObj.value));  
    AmountInMax0x = String(quoteObj.value);
  }
 
  
  setAmountIn(quotation);
  
  
  
  




  var quotecurrency = theamountpay;
  var basecurrency = quotation;
  
  
  if (Number(decimalspay) > 18) {
    basecurrency = toBN(basecurrency).div((toBN(10)).pow(toBN(decimalspay - 18)));
  
  }
  
  if (Number(decimalspay) < 18) {
    basecurrency = toBN(basecurrency).mul((toBN(10)).pow(toBN(18 - decimalspay)));
  
  }
  
  if (Number(thedecimalstoken) > 18) {
    quotecurrency = toBN(quotecurrency).div((toBN(10)).pow(toBN(thedecimalstoken - 18)));
  
  }
  
  if (Number(thedecimalstoken) < 18) {
    quotecurrency = toBN(quotecurrency).mul((toBN(10)).pow(toBN(18 - thedecimalstoken)));
  
  }
  
  
  var thepricemarket = (toBN(quotecurrency).mul(toBN(1000000)).div(toBN(basecurrency))) / 1000000;
  setpricemarket(String(thepricemarket));
  





  gasbalance = await providerprotocol.eth.getBalance(account);  
  
  
  var thegasfeetotal = toBN(thegasfee);
  if (tokenpay.toLowerCase().localeCompare('0x0000000000000000000000000000000000000000') == 0) {
    thegasfeetotal = toBN(thegasfeetotal).add(toBN(AmountInMax0x));
  }  




  if ((toBN(thebalance).gt(toBN(AmountInMax0x)) || toBN(thebalance).eq(toBN(AmountInMax0x))) && (toBN(gasbalance).gt(toBN(thegasfeetotal)) || toBN(gasbalance).eq(toBN(thegasfeetotal)))) {

        //if (toBN(thebalance).gt(toBN(AmountInMax0x)) && toBN(gasbalance).gt(toBN(thegasfee))) {
  
    
          if (tokenpay.toLowerCase().localeCompare('0x0000000000000000000000000000000000000000') !== 0) {
           
      if (toBN(theallowance).lt(toBN(AmountInMax0x))) {
            setroute(9);
            } else {
      setroute(4);  

    
      setTicking(true);
      setcounter(COUNTERDELAY);
    

    }    
          
    } else {
      setroute(4);  

    
      setTicking(true);
      setcounter(COUNTERDELAY);
    

     }  
  
  
       } else {  
         setroute(5);  
       }
      
  }
  }  
  } 
  }
}

////////////////////////////////////////////////////////////////////////////////////


if (Number(srcpool) == 0 && changebridgetokenswap == 1) {

settokenpaybridge(tokenpaybridge1);
setsrcpoolbridge(srcpoolbridge1);


settrade(4);

  
  
  if (Number(thedecimalstoken) == Number(decimalsfixed)) {
    theamountpayfixed = String(theamountpay);
    //setamountpayfixed(theamountpayfixed);
  }
    
    
    if (Number(thedecimalstoken) !== Number(decimalsfixed)) {
      var decimalsdif = 0;
    
      if (Number(thedecimalstoken) > Number(decimalsfixed)) {
        decimalsdif = thedecimalstoken - decimalsfixed;
        theamountpayfixed = toBN(theamountpay).div((toBN(10)).pow(toBN(decimalsdif)));
        //setamountpayfixed(theamountpayfixed);
      }
    
      if (Number(thedecimalstoken) < Number(decimalsfixed)) {
        decimalsdif = decimalsfixed - thedecimalstoken;
        theamountpayfixed = toBN(theamountpay).mul((toBN(10)).pow(toBN(decimalsdif)));
        
        //theamountpayfixed = ethers.utils.formatUnits(theamountpay, thedecimalstoken);
        //theamountpayfixed = ethers.utils.parseUnits(theamountpayfixed, decimalsfixed);
        //setamountpayfixed(theamountpayfixed);
      }
    
    }
    
    
  
  try{
  swapObj = await feeContract.methods.getFees(
        srcpoolbridge1,
        destpool,
        stargatechainid,
        account,
        theamountpayfixed,
      ).call()
      }catch(e){ 
      crosscheck = 1;
      
    };
  
  
  
  if (crosscheck == 1) {
  setroute(3);
  } else {
  
  
    var estfeestargate = String((toBN(theamountpayfixed).mul(toBN(MAXFEESTARGATE).mul((toBN(10)).pow(toBN(decimalsfixed))).div(toBN(10000)))).div((toBN(10)).pow(toBN(decimalsfixed))));

    var thefeestargate = toBN(swapObj.eqFee);
    thefeestargate = toBN(thefeestargate).add(toBN(swapObj.protocolFee));
    thefeestargate = toBN(thefeestargate).add(toBN(swapObj.lpFee));
    thefeestargate = toBN(thefeestargate).sub(toBN(swapObj.eqReward));
  
  if (toBN(thefeestargate).gt(toBN(estfeestargate))) {
    crosscheck = 1
  }
  
  if (crosscheck == 1) {
    setroute(3);
    } else {
  

    quotationbridge = toBN(theamountpayfixed).add(toBN(swapObj.eqFee));
    quotationbridge = toBN(quotationbridge).add(toBN(swapObj.protocolFee));
    quotationbridge = toBN(quotationbridge).add(toBN(swapObj.lpFee));
    quotationbridge = toBN(quotationbridge).sub(toBN(swapObj.eqReward));
    
    theslippagebridge = String((toBN(quotationbridge).mul(toBN(SLIPPAGESTARGATE).mul((toBN(10)).pow(toBN(decimalsfixed))).div(toBN(10000)))).div((toBN(10)).pow(toBN(decimalsfixed))));
    


    if (Number(decimalsbridge) !== Number(decimalsfixed)) {
      var decimalsdif = 0;
    
      if (Number(decimalsbridge) < Number(decimalsfixed)) {
        decimalsdif = decimalsfixed - decimalsbridge;
        quotationbridge = toBN(quotationbridge).div((toBN(10)).pow(toBN(decimalsdif)));
        theslippagebridge = toBN(theslippagebridge).div((toBN(10)).pow(toBN(decimalsdif)));
      }
    
      if (Number(decimalsbridge) > Number(decimalsfixed)) {
        decimalsdif = decimalsbridge - decimalsfixed;
        
        quotationbridge = toBN(quotationbridge).mul((toBN(10)).pow(toBN(decimalsdif)));
        theslippagebridge = toBN(theslippagebridge).mul((toBN(10)).pow(toBN(decimalsdif)));
      }
    
    }
    

    setamountslippagebridge(theslippagebridge);
    setAmountInBridge(String(quotationbridge));
    
    theamountpaybridge = String(toBN(quotationbridge).add(toBN(theslippagebridge)));
    setAmountInMaxBridge(String(theamountpaybridge));
    
  
    /////////////////////////////////////// CALCULAR GAS PARA CROSS CHAIN //////////////////

    

    var encoder = ethers.utils.defaultAbiCoder;         

    var theparams = encoder.encode([ "uint", "uint", "address", "uint", "address" ], [ '1000', chainpay, thestore, theamountpay, account ]);


  
  try{
  quotegas = await gasContract.methods.quoteLayerZeroFee(
      stargatechainid,                 			// destination chainId
      1,             			  		// function type: see Bridge.sol for all types
      routerreceiver,                  // destination of tokens
      theparams,                 // payload, using abi.encode()
      ({
          dstGasForCall: GAS_CROSS_CHAIN,       // extra gas, if calling smart contract,
          dstNativeAmount: 0,     // amount of dust dropped in destination wallet 
          dstNativeAddr: '0x' // destination wallet for dust
      })
      ).call()
    }catch(e){
     crosscheck = 1;
  
    };
  
  if (crosscheck == 1) {
  setroute(3);
  
  
  } else {
  

  thegasfee = String(toBN(quotegas[0]).mul(toBN(2)));
  setgasfee(String(thegasfee));

  


  if (Number(thedecimalstoken) == Number(decimalsbridge)) {
    theamountpayfixed = String(theamountpay);
    setamountpayfixed(theamountpayfixed);
    }
    
    
    if (Number(thedecimalstoken) !== Number(decimalsbridge)) {
      var decimalsdif = 0;
    
      if (Number(thedecimalstoken) > Number(decimalsbridge)) {
        decimalsdif = thedecimalstoken - decimalsbridge;
        theamountpayfixed = toBN(theamountpay).div((toBN(10)).pow(toBN(decimalsdif)));
        theamountpayfixed = toBN(theamountpayfixed).add(toBN(1));
        setamountpayfixed(String(theamountpayfixed));
      }
    
      if (Number(thedecimalstoken) < Number(decimalsbridge)) {
        decimalsdif = decimalsbridge - thedecimalstoken;
        theamountpayfixed = toBN(theamountpay).mul((toBN(10)).pow(toBN(decimalsdif)));
        setamountpayfixed(String(theamountpayfixed));
      }
    
    }
  



  

  


    var apiQuoteResponse;
    var quoteObj;
    var tokenpay0x = '';
    var AmountInMax0x = 0;
    
    if (tokenpay.toLowerCase().localeCompare('0x0000000000000000000000000000000000000000') == 0) {
      tokenpay0x = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';
    } else {
      tokenpay0x = thetokenpay;
    }  
    


    const params = {
    
      buyToken: tokenpaybridge1,
      sellToken: tokenpay0x,
      buyAmount: theamountpaybridge,
      slippagePercentage: SLIPPAGE0X / 10000,
      enableSlippageProtection: true,
      affiliateAddress: '0x00a1AA06490F5BB30388FD3d3d337BF4c8D13DCD',
    };
        
    
    var stringdata = qs.stringify(params);



    
      if (Number(chainpay) == 137) {
    

        apiQuoteResponse = await fetch('https://polygon.api.0x.org/swap/v1/quote?' + stringdata , {
        headers: {
        '0x-api-key': 'f94e4580-f9df-400c-aecf-6582e1c18aab'}
       });



/*    
          apiQuoteResponse = await fetch('https://polygon.api.0x.org/swap/v1/quote?' + stringdata , 
          {header: '0x-api-key: f94e4580-f9df-400c-aecf-6582e1c18aab',
         });
  */    
    
    
          quoteObj = JSON.parse(await apiQuoteResponse.text());
    
      }  
    

      if (Number(chainpay) == 43114) {
    
        
        apiQuoteResponse = await fetch('https://avalanche.api.0x.org/swap/v1/quote?' + stringdata , {
        headers: {
        '0x-api-key': 'f94e4580-f9df-400c-aecf-6582e1c18aab'}
       });
    


/*
        apiQuoteResponse = await fetch('https://avalanche.api.0x.org/swap/v1/quote?' + stringdata , 
        {header: '0x-api-key: f94e4580-f9df-400c-aecf-6582e1c18aab',
       });
*/    
  
  
        quoteObj = JSON.parse(await apiQuoteResponse.text());
  
    }  
  



      if (Number(chainpay) == 56) {
    
      
      apiQuoteResponse = await fetch('https://bsc.api.0x.org/swap/v1/quote?' + stringdata , {
      headers: {
      '0x-api-key': 'f94e4580-f9df-400c-aecf-6582e1c18aab'}
       });
      


      /*  
        apiQuoteResponse = await fetch('https://bsc.api.0x.org/swap/v1/quote?' + stringdata , 
        {header: '0x-api-key: f94e4580-f9df-400c-aecf-6582e1c18aab',
       });
      
      */ 
        
        quoteObj = JSON.parse(await apiQuoteResponse.text());
    
      }  

      



    
    if (quoteObj.code == undefined) {
    
          quotation = quoteObj.sellAmount; 
          setswaprouter(quoteObj.to);
          setpriceimpact(quoteObj.estimatedPriceImpact);
          setspender(quoteObj.allowanceTarget);
          setdata0x(quoteObj.data);

      } else {
        quotation = 0; 
      }      
     

  
  
    if (toBN(quotation).eq(toBN(0))) {
      setroute(3);
    } else {
      
  // Calculate Slippage
  


  if (tokenpay.toLowerCase().localeCompare('0x0000000000000000000000000000000000000000') !== 0) {
    theslippage = String((toBN(quotation).mul(toBN(SLIPPAGE).mul((toBN(10)).pow(toBN(decimalspay))).div(toBN(10000)))).div((toBN(10)).pow(toBN(decimalspay))));
    setamountslippage(theslippage);
    setAmountInMax(String(toBN(quotation).add(toBN(theslippage))));
    AmountInMax0x = String(toBN(quotation).add(toBN(theslippage)));
  } else {
    setAmountInMax(String(quoteObj.value));  
    AmountInMax0x = String(quoteObj.value);
  }
 
  
  setAmountIn(quotation);
  
  
  
  




  var quotecurrency = theamountpay;
  var basecurrency = quotation;
  
  
  if (Number(decimalspay) > 18) {
    basecurrency = toBN(basecurrency).div((toBN(10)).pow(toBN(decimalspay - 18)));
  
  }
  
  if (Number(decimalspay) < 18) {
    basecurrency = toBN(basecurrency).mul((toBN(10)).pow(toBN(18 - decimalspay)));
  
  }
  
  if (Number(thedecimalstoken) > 18) {
    quotecurrency = toBN(quotecurrency).div((toBN(10)).pow(toBN(thedecimalstoken - 18)));
  
  }
  
  if (Number(thedecimalstoken) < 18) {
    quotecurrency = toBN(quotecurrency).mul((toBN(10)).pow(toBN(18 - thedecimalstoken)));
  
  }
  
  
  var thepricemarket = (toBN(quotecurrency).mul(toBN(1000000)).div(toBN(basecurrency))) / 1000000;
  setpricemarket(String(thepricemarket));
  





  gasbalance = await providerprotocol.eth.getBalance(account);  
  
  
  var thegasfeetotal = toBN(thegasfee);
  if (tokenpay.toLowerCase().localeCompare('0x0000000000000000000000000000000000000000') == 0) {
    thegasfeetotal = toBN(thegasfeetotal).add(toBN(AmountInMax0x));
  }



  if ((toBN(thebalance).gt(toBN(AmountInMax0x)) || toBN(thebalance).eq(toBN(AmountInMax0x))) && (toBN(gasbalance).gt(toBN(thegasfeetotal)) || toBN(gasbalance).eq(toBN(thegasfeetotal)))) {


        //if (toBN(thebalance).gt(toBN(AmountInMax0x)) && toBN(gasbalance).gt(toBN(thegasfee))) {
  
    
          if (tokenpay.toLowerCase().localeCompare('0x0000000000000000000000000000000000000000') !== 0) {
           
      if (toBN(theallowance).lt(toBN(AmountInMax0x))) {
            setroute(9);
            } else {
      setroute(4);  

    
      setTicking(true);
      setcounter(COUNTERDELAY);
    

    }    
          
    } else {
      setroute(4);  

    
      setTicking(true);
      setcounter(COUNTERDELAY);
    

    }  
  
  
       } else {  
         setroute(5);  
       }
      
  }
  }  
  } 
  }
}


  
}




const getQuotationOnChain = async () => {






  var quotation = 0;
  var thebalance = 0; 
  var thetokenpay;
  var toBN;
  var theallowance = 0;
  var theslippage; 

  settrade(0);



  toBN = providerprotocol.utils.toBN;

  setbalancenative(await providerprotocol.eth.getBalance(account));  
  
  if (tokenpay.toLowerCase().localeCompare('0x0000000000000000000000000000000000000000') == 0) {
  thetokenpay = nativeToken;
  thebalance = await providerprotocol.eth.getBalance(account);  
  setbalancetokenpay(thebalance);  
  
  /*
  if (chainpay == 137) {
  setnametokenpay('MATIC');
  }

  if (chainpay == 56) {
    setnametokenpay('BNB');
  }

  if (chainpay == 43114) {
    setnametokenpay('AVAX');
  }
*/


} else {

  thetokenpay = tokenpay;
  thebalance = await tokencontract.methods.balanceOf(account).call(); 
  theallowance = await tokencontract.methods.allowance(account, routeraddress).call(); 
  setbalancetokenpay(thebalance);
  setallowance(theallowance);

  //setnametokenpay(await tokencontract.methods.symbol().call());

}  


if (tokenpay.toLowerCase().localeCompare(thetoken.toLowerCase()) == 0) {
  setroute(2);
  
  settrade(1);  
  
    quotation = theamountpay;  

    setAmountIn(quotation);
    setAmountInMax(String(toBN(quotation)));

    
    if (toBN(thebalance).gt(toBN(quotation))) {
      
      if (toBN(theallowance).lt((toBN(quotation)))) {
        setroute(9);
      } else {
        setroute(4);    
      }    

    
    } else {  
      setroute(5);
    
    }


}  




  if (tokenpay.toLowerCase().localeCompare(thetoken.toLowerCase()) !== 0) {
  setroute(2);   

  settrade(2);


var apiQuoteResponse;
var quoteObj;
var tokenpay0x = '';
var AmountInMax0x = 0;

if (tokenpay.toLowerCase().localeCompare('0x0000000000000000000000000000000000000000') == 0) {
  tokenpay0x = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';
} else {
  tokenpay0x = thetokenpay;
}  


const params = {
    
  buyToken: thetoken,
  sellToken: tokenpay0x,
  buyAmount: theamountpay,
  slippagePercentage: SLIPPAGE0X / 10000,
  enableSlippageProtection: true,
  affiliateAddress: '0x00a1AA06490F5BB30388FD3d3d337BF4c8D13DCD',
};


var stringdata = qs.stringify(params);





  if (Number(chainpay) == 137) {

    apiQuoteResponse = await fetch('https://polygon.api.0x.org/swap/v1/quote?' + stringdata , {
    headers: {
    '0x-api-key': 'f94e4580-f9df-400c-aecf-6582e1c18aab'}
      });


/*
      apiQuoteResponse = await fetch('https://polygon.api.0x.org/swap/v1/quote?' + stringdata , 
       {header: '0x-api-key: f94e4580-f9df-400c-aecf-6582e1c18aab',
      });
*/
      
      
      quoteObj = JSON.parse(await apiQuoteResponse.text());

  }  



  if (Number(chainpay) == 43114) {


    apiQuoteResponse = await fetch('https://avalanche.api.0x.org/swap/v1/quote?' + stringdata , {
    headers: {
    '0x-api-key': 'f94e4580-f9df-400c-aecf-6582e1c18aab'}
    });



/*
    apiQuoteResponse = await fetch('https://avalanche.api.0x.org/swap/v1/quote?' + stringdata , 
     {header: '0x-api-key: f94e4580-f9df-400c-aecf-6582e1c18aab',
    });
*/
    
    
    quoteObj = JSON.parse(await apiQuoteResponse.text());

}  











  if (Number(chainpay) == 56) {

    
    apiQuoteResponse = await fetch('https://bsc.api.0x.org/swap/v1/quote?' + stringdata , {
    headers: {
    '0x-api-key': 'f94e4580-f9df-400c-aecf-6582e1c18aab'}
   });




/*    
    apiQuoteResponse = await fetch('https://bsc.api.0x.org/swap/v1/quote?' + stringdata , 
    {header: '0x-api-key: f94e4580-f9df-400c-aecf-6582e1c18aab',
   });
*/
  
    
    quoteObj = JSON.parse(await apiQuoteResponse.text());

  }  


if (quoteObj.code == undefined) {

      quotation = quoteObj.sellAmount; 
      setswaprouter(quoteObj.to);
      setpriceimpact(quoteObj.estimatedPriceImpact);
      setspender(quoteObj.allowanceTarget);
      setdata0x(quoteObj.data);

  } else {
    quotation = 0; 
  }      



  if (toBN(quotation).eq(toBN(0))) {
    setroute(3);
    settrade(0);

  } else {
    

if (tokenpay.toLowerCase().localeCompare('0x0000000000000000000000000000000000000000') !== 0) {
theslippage = String((toBN(quotation).mul(toBN(SLIPPAGE).mul((toBN(10)).pow(toBN(decimalspay))).div(toBN(10000)))).div((toBN(10)).pow(toBN(decimalspay))));
setamountslippage(theslippage);
setAmountInMax(String(toBN(quotation).add(toBN(theslippage))));
AmountInMax0x = String(toBN(quotation).add(toBN(theslippage)));
} else {
setAmountInMax(String(quoteObj.value));  
AmountInMax0x = String(quoteObj.value);
}

setAmountIn(quotation);



var quotecurrency = theamountpay;
var basecurrency = quotation;


if (Number(decimalspay) > 18) {
  basecurrency = toBN(basecurrency).div((toBN(10)).pow(toBN(decimalspay - 18)));

}

if (Number(decimalspay) < 18) {
  basecurrency = toBN(basecurrency).mul((toBN(10)).pow(toBN(18 - decimalspay)));

}

if (Number(thedecimalstoken) > 18) {
  quotecurrency = toBN(quotecurrency).div((toBN(10)).pow(toBN(thedecimalstoken - 18)));

}

if (Number(thedecimalstoken) < 18) {
  quotecurrency = toBN(quotecurrency).mul((toBN(10)).pow(toBN(18 - thedecimalstoken)));

}


var thepricemarket = (toBN(quotecurrency).mul(toBN(1000000)).div(toBN(basecurrency))) / 1000000;
setpricemarket(String(thepricemarket));





  if (toBN(thebalance).gt(toBN(AmountInMax0x)) || toBN(thebalance).eq(toBN(AmountInMax0x))) {
      
      if (tokenpay.toLowerCase().localeCompare('0x0000000000000000000000000000000000000000') !== 0) {
        if (toBN(theallowance).lt(toBN(AmountInMax0x))) {
          setroute(9);
        } else {
          setroute(4);  


          setTicking(true);
          setcounter(COUNTERDELAY);


        }    
      } else {
        setroute(4);  


        setTicking(true);
        setcounter(COUNTERDELAY);


      } 

  } else {  
    setroute(5);
    
  }
    
  }  
 

} 





}


function SelectLanguage() {
  
  if (thelang == 1) {
    setthelang(2);
  } 

  if (thelang == 2) {
    setthelang(1);
  } 


}










return (



<div className="App">    





<div class="card">

<div class="row">

<div class="col">



{thelang == 1 &&
  <h6 class="text text-start text-dark"><a href={'https://web3debit.shop?lang=' + thelang}><img src={logo} class="img-fluid" width="40" height="auto"></img></a> <b>Pagar</b></h6>}


  {thelang == 2 &&
    <h6 class="text text-start text-dark"><a href={'https://web3debit.shop?lang=' + thelang}><img src={logo} class="img-fluid" width="40" height="auto"></img></a> <b>Pay</b></h6>}
  







</div>


<div class="col">


{thelang == 1 &&
  <h1 class="text text-end text-muted" onClick={SelectLanguage}><div class="pointer">English</div></h1>}

{thelang == 2 &&
  <h1 class="text text-end text-muted" onClick={SelectLanguage}><div class="pointer">Español</div></h1>}








</div>
</div>  


<p></p>



{isconnected == 0 &&


<div class="alert alert-secondary">
<div class="table-responsive-sm table-borderless bg-transparent">
<table class="table">
<tbody>
  








{isconnected == 0 &&

<tr>
<td>

<h1 class="text text-start">Token</h1>
</td>

<td>
{thenametoken == "USDT" &&  
<h1 class="text text-end"><img src={tether} class="img-fluid" width="20" height="auto"></img> USDT</h1>}

{thenametoken == "USDC" &&  
<h1 class="text text-end"><img src={usdc} class="img-fluid" width="20" height="auto"></img> USDC</h1>}

{thenametoken == "BUSD" &&  
<h1 class="text text-end"><img src={busd} class="img-fluid" width="20" height="auto"></img> BUSD</h1>}




</td>
</tr>}



{isconnected == 0 &&

<tr>
<td>
{thelang == 1 &&
  <h1 class="text text-start">Importe</h1>}

{thelang == 2 &&
  <h1 class="text text-start">Amount</h1>}
</td>

<td>
<h1 class="text text-end">{theamountwidget}</h1>
</td>
</tr>}






{isconnected == 0 &&


<tr>
<td>
{thelang == 1 &&
  <h1 class="text text-start">Ref #</h1>}


{thelang == 2 &&
  <h1 class="text text-start">Ref #</h1>}
</td>

<td>
  <h1 class="text text-end">{thememo}</h1>
</td>
</tr>}


{isconnected == 0 &&


<tr>
<td>
{thelang == 1 &&
  <h1 class="text text-start">a Cadena</h1>}

{thelang == 2 &&
  <h1 class="text text-start">to Chain</h1>}
</td>

<td>

  {thechainid == 137 &&
  <h1 class="text text-end"><img src={maticlogo} class="img-fluid" width="20" height="auto"></img> Polygon</h1>}


{thechainid == 43114 &&
  <h1 class="text text-end"><img src={avalanchelogo} class="img-fluid" width="20" height="auto"></img> Avalanche</h1>}




{thechainid == 56 &&
  <h1 class="text text-end"><img src={binancelogo} class="img-fluid" width="20" height="auto"></img> BNB Smart Chain</h1>}



</td>
</tr>}


{isconnected == 0 &&

<tr>
<td>
{thelang == 1 &&
  <h1 class="text text-start">Pagar a</h1>}

{thelang == 2 &&
  <h1 class="text text-start">Pay to</h1>}
</td>

<td>
  <h1 class="text text-end">{thestore.substr(0,6)}...{thestore.substr(-4)}</h1>
</td>
</tr>}




</tbody>
</table>
</div>
</div>}

<p></p>

{(thelang == 1 && isconnected == 0) &&
  <h1 class="text text-center text-muted">Conectar Wallet y Seleccionar Red y Token</h1>}


{(thelang == 2 && isconnected == 0) &&
    <h1 class="text text-center text-muted">Connect Wallet and Select Chain and Token</h1>}
  
  

  {(isconnected == 0 && thelang == 1) && 

<button type="button" class="btn btn-primary" onClick={connectWallet}>Conectar Wallet</button>}



{(isconnected == 0 && thelang == 2) && 
  <button type="button" class="btn btn-primary" onClick={connectWallet}>Connect Wallet</button>}







<div class="sticky-bottom bg-white">


{(thelang == 1 && isconnected > 0) &&
  <h1 class="text text-start text-muted">Seleccionar Cadena y Token de Pago</h1>}


{(thelang == 2 && isconnected > 0) &&
    <h1 class="text text-start text-muted">Select Chain and Token to Pay</h1>}





<div class="table-responsive-sm table-borderless bg-transparent">
<table class="table">
<tbody>






{(isconnected > 0 && route !== 12) &&
<tr>


<td>
{thelang == 1 &&
  <h1 class="text text-start">Wallet Conectada</h1>}

{thelang == 2 &&
  <h1 class="text text-start">Wallet Connected</h1>}
</td>

<td>

<h1 class="text text-end">{account.substr(0,6)}...{account.substr(-4)} <img src={desconectar} class="img-fluid-update" width="20" height="auto" onClick={disconnect}></img></h1>

</td>


</tr>}










{isconnected > 0 &&



<tr>
<td>


{thelang == 1 &&
<h1 class="text text-start">Cadena</h1>}

{thelang == 2 &&
<h1 class="text text-start">Chain</h1>}


</td>
<td>

<div class="text text-end"><select class="form-control" disabled={verdatos} onChange={handleChainPay}>


  <option value="0">Chain...</option>


  <option value="137">Polygon</option>


  <option value="56">BNB Smart Chain</option>

{(isconnected > 0 && thechainid == 43114) &&
  <option value="43114">Avalanche</option>}


</select></div>



</td>
</tr>}



{(isconnected > 0 && chainpay > 0 && Number(route) !== 12) &&
<tr>

<td>
{chainpay == 137 &&
<h1 class="text text-start">MATIC</h1>}




{chainpay == 43114 &&
<h1 class="text text-start">AVAX</h1>}




{chainpay == 56 &&
<h1 class="text text-start">BNB</h1>}
</td>


<td>
  <h1 class="text text-end">{ethers.utils.formatUnits(balancenative, 18)}</h1>
</td>


</tr>}






{(isconnected > 0 && chainpay == 137) &&


<tr>
<td>


{thelang == 1 &&
<h1 class="text text-start">Token</h1>}

{thelang == 2 &&
<h1 class="text text-start">Token</h1>}


</td>



<td>


<div class="text text-end"><select class="form-control" disabled={verdatos} onChange={handleTokenPay}>


  <option value="">Token...</option>

  
  {polygontokens.map((option, index) => (
          <option key={index} value={option.value}>
            {option.text}
          </option>
        ))}


</select></div>




</td>
</tr>}




{(isconnected > 0 && chainpay == 43114) &&


<tr>
<td>


{thelang == 1 &&
<h1 class="text text-start">Token</h1>}

{thelang == 2 &&
<h1 class="text text-start">Token</h1>}


</td>



<td>


<div class="text text-end"><select class="form-control" disabled={verdatos} onChange={handleTokenPay}>


  <option value="">Token...</option>

  
  {avalanchetokens.map((option, index) => (
          <option key={index} value={option.value}>
            {option.text}
          </option>
        ))}


</select></div>




</td>
</tr>}
















{(isconnected > 0 && chainpay == 56) &&




<tr>
<td>


{thelang == 1 &&
<h1 class="text text-start">Token</h1>}

{thelang == 2 &&
<h1 class="text text-start">Token</h1>}


</td>


<td>



  <div class="text text-end"><select class="form-control" disabled={verdatos} onChange={handleTokenPay}>


  <option value="">Token...</option>

  

  {bnbtokens.map((option, index) => (
          <option key={index} value={option.value}>
            {option.text}
          </option>
        ))}


</select></div>


</td>
</tr>}






{(isconnected > 0 && route > 0 && route !== 12) &&
<tr>
<td>
<h1 class="text text-start">Bal</h1>
</td>

<td>

<h1 class="text text-end">{ethers.utils.formatUnits(balancetokenpay, decimalspay)}</h1>

</td>
</tr>}

















{(isconnected > 0 && (route == 4 || route == 5 || route == 8 || route == 9 || route == 6 || route == 10) && trade == 1) &&
<tr>
<td>
{thelang == 1 &&
<h1 class="text text-start">Exacto</h1>}
{thelang == 2 &&
<h1 class="text text-start">Exact</h1>}

</td>

<td>

<h1 class="text text-end">{ethers.utils.formatUnits(amountInMax, decimalspay)}</h1>

</td>
</tr>}



{(isconnected > 0 && (route == 4 || route == 5 || route == 8 || route == 9 || route == 6 || route == 10) && (trade == 2 || trade == 3 || trade == 4)) &&
<tr>
<td>
{thelang == 1 &&
<h1 class="text text-start">Est~</h1>}
{thelang == 2 &&
<h1 class="text text-start">Est~</h1>}

</td>

<td>

<h1 class="text text-end">{ethers.utils.formatUnits(amountIn, decimalspay)}</h1>

</td>
</tr>}





{(isconnected > 0 && (route == 4 || route == 5 || route == 8 || route == 9 || route == 6 || route == 10) && (trade == 2 || trade == 3 || trade == 4)) &&
<tr>
<td>
{thelang == 1 &&
<h1 class="text text-start">Max</h1>}
{thelang == 2 &&
<h1 class="text text-start">Max</h1>}

</td>

<td>

<h1 class="text text-end">{ethers.utils.formatUnits(amountInMax, decimalspay)}</h1>

</td>
</tr>}


{(isconnected > 0 && (route == 4 || route == 5 || route == 8 || route == 9 || route == 6 || route == 10) && (trade == 3 || trade == 4)) &&
<tr>
<td>
{thelang == 1 &&
<h1 class="text text-start">Gas~ <img src={info} class="img-fluid" width="15" height="auto" data-toggle="tooltip" data-placement="bottom" title="comisión cross chain swap. Devolución ~50%"></img></h1>}
{thelang == 2 &&
<h1 class="text text-start">Gas~ <img src={info} class="img-fluid" width="15" height="auto" data-toggle="tooltip" data-placement="bottom" title="cross chain swap fee. Refund ~50%"></img></h1>}

</td>

<td>

<h1 class="text text-end">{ethers.utils.formatUnits(gasfee, 18)}</h1>

</td>
</tr>}


{(isconnected > 0 && (route == 4 || route == 5 || route == 8 || route == 9 || route == 6 || route == 10) && (trade == 2 || trade == 3 || trade == 4)) &&
<tr>
<td>
{thelang == 1 &&
<h1 class="text text-start">Precio</h1>}
{thelang == 2 &&
<h1 class="text text-start">Price</h1>}

</td>

<td>

<h1 class="text text-end">1 {nametokenpay} = {pricemarket} {thenametoken}</h1>

</td>
</tr>}


{(isconnected > 0 && (route == 4 || route == 5 || route == 8 || route == 9 || route == 6 || route == 10) && (trade == 2 || trade == 4)) &&
<tr>
<td>
{thelang == 1 &&
<h1 class="text text-start">Impacto Precio</h1>}
{thelang == 2 &&
<h1 class="text text-start">Price Impact</h1>}

</td>

<td>


{priceimpact == null &&
  <h1 class="text text-end">N/A</h1>}

{(priceimpact !== null && priceimpact <= 5) &&
<h1 class="text text-end">{priceimpact} %</h1>}

{(priceimpact !== null && priceimpact > 5) &&
<h1 class="text text-end text-danger"><b>{priceimpact} %</b></h1>}



</td>
</tr>}




</tbody>
</table>
</div>






{(isconnected > 0 && route == 2) &&


<div>

{thelang == 1 &&


<div class="alert alert-success" role="alert">
<b>Calculando...</b>
</div>}


{thelang == 2 &&

<div class="alert alert-success" role="alert">
<b>Calculating...</b>
</div>}

</div>}





{(isconnected > 0 && route == 6) &&

<div>

{thelang == 1 &&

<div class="alert alert-success" role="alert">
<b>Confirmar en wallet...</b>
</div>}




{thelang == 2 &&

<div class="alert alert-success" role="alert">
<b>Confirm in wallet...</b>
</div>}

</div>}



{(isconnected > 0 && route == 8) &&


<div>

{thelang == 1 &&

<div class="alert alert-danger" role="alert">
<b>Transaccion fallida</b>
</div>}



{thelang == 2 &&

<div class="alert alert-danger" role="alert">
<b>Transaction failed</b>
</div>}

</div>}




{(isconnected > 0 && route == 5) &&

<div>

{thelang == 1 &&

<div class="alert alert-danger" role="alert">
<b>Balance Insuficiente</b>
</div>}


{thelang == 2 &&

<div class="alert alert-danger" role="alert">
<b>Insufficient Balance</b>
</div>}

</div>}




{(isconnected > 0 && route == 7) &&

<div>

{thelang == 1 &&


<div class="alert alert-success" role="alert">
<b>Transaccion enviada. Pendiente...</b>
</div>}


{thelang == 2 &&

<div class="alert alert-success" role="alert">
<b>Transaction sent. Pending...</b>
</div>}


</div>}





{(isconnected > 0 && route == 10) &&

<div>

{thelang == 1 &&


<div class="alert alert-success" role="alert">
<b>Aprobacion Confirmada</b>
</div>}


{thelang == 2 &&

<div class="alert alert-success" role="alert">
<b>Approval Confirmed</b>
</div>}


</div>}




{(isconnected > 0 && route == 12) &&

<div>

{thelang == 1 &&


<div class="alert alert-success" role="alert">
<b>Pago Confirmado</b>
</div>}




{thelang == 2 &&


<div class="alert alert-success" role="alert">
<b>Payment Confirmed</b>
</div>}




</div>}




{(isconnected > 0 && route == 3) &&

<div>

{thelang == 1 &&


<div class="alert alert-danger" role="alert">
<b>No se encontro Cotizacion</b>
</div>}


{thelang == 2 &&

<div class="alert alert-danger" role="alert">
<b>No Quote found</b>
</div>}


</div>}









  





{((route == 1 || route == 8 || route == 5 || route == 3 || route == 10) && thelang == 1) &&
<button type="button" class="btn btn-primary" onClick={getQuotation}>Calcular Pago</button>} 

{((route == 1 || route == 8 || route == 5 || route == 3 || route == 10) && thelang == 2)&&
<button type="button" class="btn btn-primary" onClick={getQuotation}>Calculate Payment</button>} 




{((route == 4) && trade > 1 && thelang == 1 && Number(chainid) == Number(chainpay)) &&
<button type="button" class="btn-pay" onClick={payPurchase}>PAGAR ( {counter} )</button>} 


{((route == 4) && trade > 1 && thelang == 2 && Number(chainid) == Number(chainpay)) &&
<button type="button" class="btn-pay" onClick={payPurchase}>PAY ( {counter} )</button>} 



{((route == 4) && trade == 1 && thelang == 1 && Number(chainid) == Number(chainpay)) &&
<button type="button" class="btn-pay" onClick={payPurchase}>PAGAR</button>} 


{((route == 4) && trade == 1 && thelang == 2 && Number(chainid) == Number(chainpay)) &&
<button type="button" class="btn-pay" onClick={payPurchase}>PAY</button>} 






{((route == 4 || route == 9) && thelang == 1 && Number(chainid) !== Number(chainpay)) &&
<button type="button" class="btn btn-primary" onClick={switchNetwork}>Cambiar Cadena</button>} 


{((route == 4 || route == 9) && thelang == 2 && Number(chainid) !== Number(chainpay)) &&
<button type="button" class="btn btn-primary" onClick={switchNetwork}>Switch Chain</button>} 




{(route == 9 && thelang == 1 && Number(chainid) == Number(chainpay)) &&
<button type="button" class="btn btn-primary" onClick={approveToken}>Aprobar {nametokenpay}</button>} 


{(route == 9 && thelang == 2 && Number(chainid) == Number(chainpay)) &&
<button type="button" class="btn btn primary" onClick={approveToken}>Approve {nametokenpay}</button>} 



{(route == 12 && thelang == 1) &&
<a href={"https://web3debit.shop/?lang=" + thelang}><button type="button" class="btn-pay">Salir</button></a>} 


{(route == 12 && thelang == 2) &&
  <a href={"https://web3debit.shop/?lang=" + thelang}><button type="button" class="btn-pay">Exit</button></a>} 






</div>




</div>

</div>

);

}


export default Finish;